import React from 'react';
import Professor from './Professor';
import Title from '../../../../components/Text';

function ListProfessor({teachers, deleteTeacherAction}) {

  const teachersInfoList = teachers.map((teacher, index) => {
    const totalStudentByClassRoom = teacher.classRooms
      .map((classRoom) => classRoom.students.length)
      .reduce((val, current) => { return val + current }, 0)
    return (<Professor key={teacher.id} professorId={teacher.id} professorName={`${teacher.firstName} ${teacher.lastName}`} numberOfClass={teacher.classRooms.length} numberOfStudent={totalStudentByClassRoom ?? 0} deleteTeacherAction={deleteTeacherAction} />)
})

  return (
    <div className='proflist'>
      <Title className='proflist_title smalltitleOrange' text='Professeurs utilisant Holy Owly'/>
      <div className='proflist_list'>
        {teachersInfoList}
      </div>

    </div>
  )
}

export default ListProfessor;