import React from "react";

function LoginForm({labelText, loginForm_inputPlaceholder, loginForm_inputName, loginForm_inputType, inputRef}) {
  return (
    <div className='loginForm'>
      <label className='loginForm_label'>{labelText}</label>
      <input
        className='loginForm_input'
        placeholder={loginForm_inputPlaceholder}
        name={loginForm_inputName}
        {...inputRef}
        type={loginForm_inputType}/>
    </div>
  )
}

export default LoginForm;