import React from "react";
import AboutList from "./aboutList";

function About() {
  return (
    <div className='about'>
      <h3 className='about_title textFooterMedium'>
      À propos
      </h3>

      <ul className='about_menu aboutMenuList'>
        {
          AboutList.map((item, index) => {
            return (
              <li className='about_list textFooterSmall' key={index}>
                <a className={item.cName} href = {item.url}></a>
                {item.title}
              </li>
            )
          })
        }
      </ul>
      
    </div>
  )
}

export default About;