import React from "react";

function DeleteButton({deleteText, onClick}) {
  return (
    <a href="#" onClick={onClick}>
    <div className='deleteButton'>
      <p>{deleteText}</p>
    </div>
    </a>
  )
}

export default DeleteButton;