import React, { useEffect, useState, useContext } from "react";
import getFirebase from "../../../utils/firebase";
import "firebase/auth";
import firebase from "firebase/app";
import "firebase/firestore";
import IntroChoiceAdminType from "./INtroChoiceAdminType";
import { BrowserRouter as Router, useHistory } from "react-router-dom";

import useRadioButtons from "../../../hook/useRadioButtons";
import { UserContext } from "../../../app";
import Button from "../../../components/Button";

export default function ChoiceAdminType() {
  const firebaseInstance = getFirebase();
  const history = useHistory();
  const db = firebase.firestore();

  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  // hooks
  const useShouldBeAdminTeacher = useRadioButtons("shouldBeAdminTeacher", null);
  const [shouldBeAdminTeacher, shouldBeAdminTeacherProps] =
    useShouldBeAdminTeacher;

  const submitForm = async (event) => {
    event.preventDefault();

    const isAdminTeacher = shouldBeAdminTeacher == "true" ? true : false;
    const adminCollection = db.collection("admin");
    const currentAdminDocRef = adminCollection.doc(user.id);

    if ((firebaseInstance, user.isSignIn())) {
      if (isAdminTeacher && user.adminTeacher == undefined && user.isAdmin()) {
        try {
          const firstName = user.chefEtabPrenom;
          const lastName = user.chefEtabNom;
          const email = user.email;
          const zone = "c";

          const db = firebase.firestore();
          const docRef = db.collection("teacher");
          const newTeacherInfo = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            classRooms: [],
            nbOfStudent: 0,
            adminUid: user.uid,
            defaultPassword: false,
            uid: user.uid,
            zone: zone,
            admin: db.collection("admin").doc(user.id),
            creationDate: firebase.firestore.Timestamp.fromDate(new Date()),
          };
          const newTeacher = await docRef.doc();

          await newTeacher.set(newTeacherInfo);

          // Update admin to add the teacher
          console.log(user.id);

          const adminTeacher = { ...newTeacherInfo, id: newTeacher.id };
          const newAdminTeacherRef = db.doc(`teacher/${adminTeacher.id}`);
          let nbRemainedLicences =
            (await user.getNumberOfRemainedLicence()) - 1;

          const firstTimeChoiceAdminType = false;
          await currentAdminDocRef.set(
            {
              adminTeacher: newAdminTeacherRef,
              nbRemainedLicences,
              firstTimeChoiceAdminType,
            },
            { merge: true }
          );
          setUser({
            ...user,
            nbRemainedLicences,
            adminTeacher: adminTeacher,
            firstTimeChoiceAdminType,
          });
        } catch {
          err;
          console.error(err);
        }
      } else {
        try {
          const firstTimeChoiceAdminType = false;
          await currentAdminDocRef.set(
            { firstTimeChoiceAdminType },
            { merge: true }
          );
          setUser({ ...user, firstTimeChoiceAdminType });
        } catch {
          err;
          console.error(err);
        }
      }
    }
  };
  return (
    <div className="pageChoiceAdminType">
      <IntroChoiceAdminType />
      <form
        className="pageChoiceAdminType_form pageChoiceAdminTypeForm"
        onSubmit={submitForm}
      >
        <div className="pageChoiceAdminTypeForm_choices adminTypeChoices">
          <div className="admin_choice adminChoice">
            <label className="adminChoice_boxText">
              <h3 className="adminChoiceBoxText_title">
                Vous êtes administrateur
              </h3>
              <p className="adminChoiceBoxText_text">
                L’administrateur gère UNIQUEMENT les professeurs qui utiliseront
                Holy Owly avec leur classe.
              </p>
              <a
                href={
                  "https://static.holyowly.fr/school/HolyOwlyForSchool-Administrateur.mp4"
                }
                className="adminChoice_videoLink"
              >
                EN SAVOIR PLUS
              </a>
            </label>
            <input
              className="adminChoice_radioType"
              required
              type="radio"
              value="false"
              {...shouldBeAdminTeacherProps}
            />
          </div>

          <div className="admin_choice adminChoice">
            <label className="adminChoice_boxText">
              <h3 className="adminChoiceBoxText_title">
                Vous êtes administrateur et professeur
              </h3>
              <p className="adminChoiceBoxText_text">
                {user.isTeacherLicence()
                  ? "L’administrateur - professeur utilisera Holy Owly avec sa propre classe. Il gère également les professeurs qui l’utiliseront avec leur classe. En sélectionnant cette option, vous activerez une licence professeur pour vous-même."
                  : "L’administrateur - professeur utilisera Holy Owly avec sa propre classe. Il gère également les professeurs qui l’utiliseront avec leur classe."}{" "}
              </p>
              <a
                href={
                  "https://static.holyowly.fr/school/HolyOwlyForSchool-AdminProf.mp4"
                }
                className="adminChoice_videoLink"
              >
                EN SAVOIR PLUS
              </a>
            </label>
            <input
              className="adminChoice_radioType"
              required
              type="radio"
              value="true"
              {...shouldBeAdminTeacherProps}
            />
          </div>
        </div>
        <Button
          type="submit"
          className="pageChoiceAdminTypeForm_button"
          text="VALIDER"
        />
      </form>
    </div>
  );
}
