import React from "react";
import SiteMapList from "./SiteMapList";

function SiteMap() {
  return (
    <div className='siteMap'>
      <h3 className='siteMap_title textFooterMedium'>
        Plan du site
      </h3>

      <ul className='siteMap_menu siteMapMenuList'>
        {
          SiteMapList.map((item, index) => {
            return (
              <li className='siteMap_list textFooterSmall' key={index}>
                <a className={item.cName} href = {item.url}></a>
                {item.title}
              </li>
            )
          })
        }
      </ul>
      
    </div>
  )
}

export default SiteMap;