import React from 'react';

function Professor({professorId, professorName, numberOfClass, numberOfStudent, deleteTeacherAction }) {
  return (
    <div className ='professor'>
      <div className='professor_infos profesorInfos'>
        <p className='textOrangeMedium profesorInfos_name'>{professorName}</p>
        <div className='professorTotalClass profesorInfos_totalClass'>
          <p className='textGreyMedium'>{numberOfClass} { (numberOfClass > 1 ) ? 'Classes' : 'Classe'}</p>
          <p className='textGreyMedium'>{numberOfStudent} élèves</p>
        </div>
      </div>
      <a href="#">
       <img onClick={async () => deleteTeacherAction(professorId)} className='professor_deleteButton' src={require('../../../../assets/images/icon-deleteProf.svg')} />
      </a>
    </div>
  )
}

export default Professor;