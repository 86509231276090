import React from "react";
import MenuItems from "./components/MenuItems";

function HeaderOnlyLogo() {
  return (
    <div className='headerOnlyLogo'>
      <img className='headerOnlyLogo_hoLogo' src={require('../../assets/images/homePage/logo-HOforSchool.svg')} />
    </div>
  )
}

export default HeaderOnlyLogo;