import React, { useContext } from "react";
import LabelForm from "../../../Home/components/LabelForm";
import firebase from 'firebase/app'
import 'firebase/firestore'
import { UserContext } from "../../../../app"
import useRadioButtons from "../../../../hook/useRadioButtons"
import useInput from "../../../../hook/useInput"
import classRoomExtension from '../../../../extension/classRoomExtension'
import generator from 'generate-password-browser';
import useGradeSchoolSelect from "../../../../hook/useGradeSchoolSelect"
import Button from "../../../../components/Button";
import Guide from "../EditClassComplex/Guide";


function FormAddClassName({isTeacherLicence, handleSubmitForm, useGradeSchool, useName, useHasGuide, isAllowToAddChildren}) {

  const handleChangeSelect = (event) => {
    const val = event.target.value
    useGradeSchool.setValue(event.target.value);
  };

  return (
    <div className='formAddClasses'>
      <form onSubmit={handleSubmitForm} className=' formClassNameNiveau'>
        <div className='formClassNameNiveau_form'>
          <div className='formAddClassName formNiveau'>
          <label className='formAddClassName_label'>Niveau des élèves du groupe</label>
          <select  name="classLevel" className='formAddClassName_input addStudentClassInput' required value={useGradeSchool.value} onChange={handleChangeSelect}>
            {
              useGradeSchool.schoolGrade.map((schoolGrade) => {
                return (<option className='selectValue' key={schoolGrade} value={schoolGrade}>{schoolGrade}</option>)
              })
            }
          </select>
          </div>
          <LabelForm labelFormClassName='formAddClassName'
            labelClassName='formAddClassName_label'
            labelText='Nom du groupe'
            inputClassName='addStudentClassInput'
            inputName='className'
            inputPlaceholder='CP'
            useInput={useName}
            required={true}
          />
        </div>
        {/* <Guide useHasGuide={useHasGuide}/> */}
        <Button text={isAllowToAddChildren ? "CONTINUER" : "VALIDER"} className='addClassFirstTime_buttonContinue'/>
      </form>
  </div>)
}

export default FormAddClassName