import React, { useContext } from "react";
import MenuProfList from "./MenuProfList";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import getFirebase from "../../../utils/firebase"
import { UserContext } from "../../../app"
//the style is using the same className like in the component ** adminMenu **

function ProfMenu() {

  const match = useRouteMatch();
  const firebase = getFirebase();
  // context
  const { useUser, isLoading } = useContext(UserContext)
  const [user, setUser] = useUser
  const isTeacherLicence = (user.isAdmin()) ? user.isTeacherLicence() : user.admin.isTeacherLicence()
  
  const logoutClicked = async () => {
    setUser(null)
    await firebase.auth().signOut()
}

  if (!isLoading && user.isSignIn()) {
      console.log(user.isTeacher())
  }

  return (
    <div className='adminMenu'>
      <h3 className='adminMenu_title'>
        Gestion du compte
      </h3>

      <ul className='adminMenu_menu adminMenuList'>
      {
          MenuProfList.map((item, index) => {
            if (item.id === 'profStudentsMenu') {
              if (user.isTeacherLicence()) {
                //item.title === 'vos groupes'
                
                return (
                  <Link className={item.itemClassName} to={`${match.path}/${item.subPath}`}>
                  <li className='adminMenuList_list' key={index}>
                    Vos groupes
                  </li>
              </Link>)
                //return null
              }
            }
            return (
              <Link className={item.itemClassName} to={`${match.path}/${item.subPath}`}> 
                <li className='adminMenuList_list' key={index}>
                {item.title} 
                </li>
              </Link>
          )})
        }
        <a className='adminMenuItem'href="#" onClick={logoutClicked}>
         <li className='adminMenuList_list'>
            Déconnexion
          </li>
        </a>
      </ul>

      <a href='https://www.editions-bordas.fr/recherche/type/P?text=holy%20owly' className='buttonAsset'>Guide pédagogique</a>
      
    </div>
  )
}

export default ProfMenu;