import TitleConnection from "./Components/titleConnection";
import LoginForm from "./Components/LoginForm";
import ProblemConnection from "./Components/problemConnection";
import Button from "../../components/Button";

import React, { useEffect, useContext, useState } from "react";
import getFirebase from "../../utils/firebase";
import "firebase/auth";
import "firebase/firestore";
import useInput from "../../hook/useInput";
import { UserContext } from "../../app";
import Loader from "../../components/Loader";

function UserConnexion() {
  const firebase = getFirebase();
  const db = firebase.firestore();
  const auth = firebase.auth();

  // Ref
  const emailInput = useInput("");
  const passwordInput = useInput("");

  // context
  const { useUser, isLoading } = useContext(UserContext);

  const [user, setUser] = useUser;

  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  useEffect(() => {
    console.log(user.isSignIn());
    if (user.isSignIn()) {
      if (user.isAdmin()) {
        setIsLoadingRequest(true);
        history.push("/admin");
      } else if (user.isTeacher()) {
        history.push("/teacher");
      }
    }
  }, [user]);

  const signInWithEmailPassword = async (e) => {
    e.preventDefault();
    if (isLoadingRequest) {
      return;
    }
    setIsLoadingRequest(true);
    // debugger;
    try {
      if (!firebase) return;
      const data = await auth.signInWithEmailAndPassword(
        emailInput.value,
        passwordInput.value
      );
      console.log(user.isSignIn());
    } catch (error) {
      setIsLoadingRequest(false);
      if (error.code === "auth/invalid-email") {
        alert("L'email n'est pas valide");
      }
      if (error.code === "auth/user-not-found") {
        alert("L'utilisateur n'existe pas");
      }
      if (error.code === "auth/wrong-password") {
        alert("le mot de passe n'est pas valide");
      }
      console.log(error);
    }
  };

  return (
    <div className="adminConnection">
      <form className="adminConnection_form" onSubmit={signInWithEmailPassword}>
        <TitleConnection />
        <LoginForm
          labelText="Adresse e-mail"
          loginForm_inputPlaceholder="Votre adresse e-mail"
          loginForm_inputName="profEmail"
          loginForm_inputType="text"
          inputRef={emailInput}
        />
        <LoginForm
          labelText="Mot de passe"
          loginForm_inputPlaceholder="Votre mot de passe"
          loginForm_inputName="profPassword"
          loginForm_inputType="password"
          inputRef={passwordInput}
        />
        {isLoadingRequest ? <Loader /> : null}

        <Button
          type="submit"
          className="adminConnection_buttonValid"
          text="VALIDER"
        />
        <ProblemConnection />
      </form>
    </div>
  );
}

export default UserConnexion;
