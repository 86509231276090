import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import ListProfessor from "../components/ManagementProfs/ListProfessors";
import userExtension from "../../../extension/userExtension";

export default function TeacherGenerationGetDelete({ teachersState }) {
  let match = useRouteMatch();
  const firebase = getFirebase();

  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  const deleteTeacherAction = async (idTeacher) => {
    const db = firebase.firestore();
    const ref = db.collection("teacher");
    try {
      let newListOfTeacher = user.teachers.filter(
        (teacher) => teacher.id != idTeacher
      );

      if (newListOfTeacher === undefined) {
        newListOfTeacher = [];
      }

      const db = firebase.firestore();
      const adminCollection = db.collection("admin");
      const currentAdminDocRef = adminCollection.doc(user.id);
      const newAdminTeacherListRef = newListOfTeacher.map((teacher) =>
        db.doc(`teachers/${teacher.id}`)
      );

      await ref.doc(idTeacher).delete();

      const userUpdated = userExtension({
        ...user,
        teachers: newListOfTeacher,
      });

      await currentAdminDocRef.set(
        {
          teachers: newAdminTeacherListRef,
        },
        { merge: true }
      );

      setUser(userUpdated);

      let nbRemainedLicences = await userUpdated.getNumberOfRemainedLicence();

      await currentAdminDocRef.set(
        {
          nbRemainedLicences,
        },
        { merge: true }
      );

      setUser({ ...userUpdated, nbRemainedLicences });
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  let container;
  if (!isLoading && user.isSignIn()) {
    const totalStudentByClassRoom = 0;

    let shouldDisplayTeacherList = user.teachers.length > 0;
    if (!shouldDisplayTeacherList) {
      container = (
        <h2 className="noProflist">
          Vous n'avez inscrit aucun professeur pour le moment
        </h2>
      );
    } else {
      container = (
        <ListProfessor
          teachers={user.teachers}
          deleteTeacherAction={deleteTeacherAction}
        />
      );
    }
  }

  return <div>{!isLoading ? container : ""}</div>;
}
