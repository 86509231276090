import React, { useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import getFirebase from "./utils/firebase";
import "firebase/auth";
import "firebase/firestore";
import useUser from "./hook/useUser";

import SignIn from "./pages/SignIn/SignIn";
import AdminHome from "./pages/Admin/AdminHome";
import "./styles/style.scss";
import School from "./pages/School/PageSchool";
import TeacherHome from "./pages/Teacher/TeacherHome";

export const UserContext = React.createContext();
import PrintClassPage from "./pages/teacher/printClass/printClassPage";
import HeaderConnection from "./components/Header/HeaderConnection";
import HeaderLoading from "./components/Header/HeaderLoading";
import Loader from "./components/Loader";

export default function App() {
  const firebase = getFirebase();
  const adminState = useState({});
  const FAKE_SESSION_ID_CLIENT = "CNS_122338";
  const history = useHistory();
  const userHook = useUser();
  const isLoading = userHook.isLoading;
  const [user, setUser] = userHook.useUser;

  return (
    <UserContext.Provider value={userHook}>
      <Router>
        <Switch>
          <Route path={`/printClass/:classRoomId`} component={PrintClassPage} />
          <PrivateRoute path="/admin" component={AdminHome} type="admin" />
          <PrivateRoute
            path="/teacher"
            component={TeacherHome}
            type="teacher"
          />
          <Route path="/signIn">
            <SignIn />
          </Route>
          <Route path="/">
            <School />
          </Route>
        </Switch>
      </Router>
    </UserContext.Provider>
  );
}

// https://stackoverflow.com/questions/43164554/how-to-implement-authenticated-routes-in-react-router-4
function PrivateRoute({ component: Component, ...rest }) {
  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  return (
    <Route
      {...rest}
      render={(props) => {
        return user.isSignIn() &&
          (user.type == rest.type ||
            (rest.type == "teacher" && user.isTeacher())) ? (
          <Component {...props} />
        ) : !isLoading ? (
          <Redirect
            to={{
              pathname: "/signIn",
              state: { from: props.location },
            }}
          />
        ) : (
          <>
            <HeaderLoading />
            <Loader />
          </>
        );
      }}
    />
  );
}
