import React from 'react';
import About from './components/About';
import SiteMap from './components/SiteMap';
import SocialMedia from './components/SocialMedia';
import CopyRight from './components/CopyRight';
import HolyOwlyApp from './components/HolyOwlyApp';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer_mainPart'>
        <HolyOwlyApp/>
        <About />
        <SiteMap />
        <SocialMedia />
      </div>
      <CopyRight />
    </div>
  )
}

export default Footer;



