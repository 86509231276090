import React from "react";
import Button from "../../../../components/Button";
import LabelForm from "../../../Home/components/LabelForm";

function AddProfessorForm({
  submitForm,
  innerEmailRef,
  innerFirstNameRef,
  innerLastNameRef,
  nbRemainedLicences,
  zoneSelect,
  isLicenceTeacher,
}) {
  return (
    <div className="addProfessorForm">
      {(nbRemainedLicences > 0 && isLicenceTeacher) || !isLicenceTeacher ? (
        <form onSubmit={submitForm} className="addProfessorForm_form">
          <fieldset className="formAddProf">
            <LabelForm
              innerRef={innerLastNameRef}
              labelFormClassName="formInfo"
              labelClassName="addProfInfo_label addProfInfo"
              required={true}
              labelText="Nom du professeur"
              inputClassName="addProfInfo_input formInfo_infoInput"
              inputName="profFirstName"
            />

            <LabelForm
              innerRef={innerFirstNameRef}
              labelFormClassName="formInfo"
              required={true}
              labelClassName="addProfInfo_label addProfInfo"
              labelText="Prénom du professeur"
              inputClassName="addProfInfo_input formInfo_infoInput"
              inputName="profLastName"
            />

            <LabelForm
              innerRef={innerEmailRef}
              inputType="email"
              required={true}
              labelFormClassName="formInfo"
              labelClassName="addProfInfo_label addProfInfo"
              labelText="Adresse e-mail du professeur"
              inputClassName="addProfInfo_input formInfo_infoInput"
              inputName="profEmail"
            />

            <div className="formAddProf_zone profZone">
              <label className="profZone_label" for="profZone">
                Zone
              </label>
              <select
                className="profZone_select"
                name="profZone"
                value={zoneSelect.value}
                onChange={zoneSelect.handleChangeSelect}
              >
                {zoneSelect.zone.map((zone) => {
                  return (
                    <option name="profZone" key={zone} value={zone}>
                      {zone}
                    </option>
                  );
                })}
              </select>
            </div>

            <Button
              className="buttonValidation buttonValidationForm"
              type="submit"
              text="ENVOYER"
            />
          </fieldset>
        </form>
      ) : (
        <p className="addProfessorForm_fullList">
          Vous avez atteint le maximum de professeurs inscrit. Pour ajouter plus
          de professeurs souscrivez à un nouvel abonnement.
        </p>
      )}
    </div>
  );
}

export default AddProfessorForm;
