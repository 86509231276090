const SocialMediaList = [
  {
    title: 'Facebook',
    src: '',
    cName: 'socialMediaLink'
  },
  {
    title: 'Instagram',
    src: '',
    cName: 'socialMediaLink'
  },
  {
    title: 'Twitter',
    src: '',
    cName: 'socialMediaLink'
  },
  {
    title: 'Youtube',
    src: '',
    cName: 'socialMediaLink'
  },
]

export default SocialMediaList;