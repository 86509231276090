import React, { useState } from 'react';


const useZoneSelect = (initialValue) => {
    const zone = [
        "A",
        "B",
        "C"
    ]
    const [value, setValue] = useState(initialValue);

    const handleChangeSelect = (event) => {
        const val = event.target.value
        setValue(event.target.value);
    };

    return {
        zone,
        value,
        setValue,
        handleChangeSelect
    };
};

export default useZoneSelect