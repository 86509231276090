import React, { useContext } from "react";
import Title from "../../components/Text";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

import StudentGenerationGetDelete from "./studentGeneration/StudentGenerationGetDelete";
import ClassGenerationGetPut from "./classGeneration/ClassGenerationGetPut";
import TeacherGenerationPut from "./teacherGeneration/TeacherGenerationPut";
import { UserContext } from "../../app";
import MenuProf from "./components/MenuProf";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import StudentGenerationGet from "./teacherGeneration/TeacherGenerationGet";
import AddClassFirstTime from "./components/EditClassComplex/AddClassFirstTime";
import PopUpSuccesAddClass from "../../components/PopUpSuccesAddClass";
import ClassStudentGenerationPost from "./classStudentGeneration/ClassStudentGenerationPost";
import StudentReport from "./studentGeneration/StudentReport";

export default function TeacherHome() {
  const match = useRouteMatch();
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;
  const isTeacherLicence = user.isAdmin()
    ? user.isTeacherLicence()
    : user.isTeacherLicence();

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <Header />
      <div className="pageProf">
        <Title
          className="titlePurple  pageAdmin_title"
          text="Bienvenue dans votre espace <br/>
        professeur !"
        />
        <MenuProf />
        <Switch className="pageProf_contents">
          <Route path={`${match.path}/addGroup`}>
            <ClassStudentGenerationPost />
          </Route>
          {/* <Route path={`${match.path}/addGroup`}>
                        <PopUpSuccesAddClass redirectPath={`${match.path}/student`} />
                    </Route> */}
          <Route path={`${match.path}/info`}>
            <StudentGenerationGet />
          </Route>
          <Route
            path={`${match.path}/studentReport/:studentId`}
            children={<StudentReport redirectPath={match.path} />}
          />

          <Route path={match.path}>
            <StudentGenerationGetDelete />
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );
}
