import React, { useState, useEffect, useContext } from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import { useEmblaCarousel } from "embla-carousel/react";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import ClassGenerationPostPopup from "./ClassGenerationPostPopup";
import StudentGenerationPostPopup from "../studentGeneration/StudentGenerationPostPopup";
import "./ClassGenerationGet.css";
import StudentGenerationPutPopup from "../studentGeneration/StudentGenerationPutDeletePopup";

export default function ClassGenerationGetPut() {
  const firebaseInstance = getFirebase();
  let match = useRouteMatch();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    draggable: false,
  });
  const firebase = getFirebase();
  const history = useHistory();
  // context
  const [inputClassStudent, setInputClassStudent] = useState([]);
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  const classRooms = user.isAdmin()
    ? user.adminTeacher.classRooms
    : user.classRooms;
  const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
  const teacher = user.isAdmin() ? user.adminTeacher : user;
  const isTeacherLicence = user.isAdmin()
    ? user.isTeacherLicence()
    : user.admin.isTeacherLicence();
  const admin = user.isAdmin() ? user : user.admin;
  const nbLicences = admin.nbLicences;
  const nbRemainedLicences = admin.nbRemainedLicences;
  const hasRemainedLicences = nbRemainedLicences > 0;

  useEffect(() => {
    if (emblaRef != undefined && emblaApi != undefined) {
      emblaApi.reInit();
    }
  }, [user]);

  useEffect(() => {
    const classStudentUseInputs = classRooms.map((classItem) => {
      return classItem.students.map((studentItem) => {
        const id = studentItem.id;
        const firstName = studentItem.firstName;
        const lastName = studentItem.lastName;
        const classRoomId = classItem.id;
        return {
          id,
          firstName,
          lastName,
          classRoomId,
        };
      });
    });

    setInputClassStudent(classStudentUseInputs);
  }, [user]);

  const updateStudentClassRoom = async (classRoomId) => {
    if ((firebaseInstance, user.isSignIn())) {
      const db = firebase.firestore();
      const collectionStudent = db.collection("student");

      const indexClassRoomId = inputClassStudent.findIndex((studentList) => {
        return (
          studentList.findIndex(
            (student) => student.classRoomId == classRoomId
          ) != -1
        );
      });

      if (indexClassRoomId == -1) {
        alert("Il n'y a pas d'élève dans cette classe");
        return;
      }

      for (const studentInputInfo of inputClassStudent[indexClassRoomId]) {
        console.log(studentInputInfo);
        let studentDocRef = collectionStudent.doc(studentInputInfo.id);

        const { firstName, lastName } = studentInputInfo;

        await studentDocRef.set(
          {
            firstName,
            lastName,
          },
          { merge: true }
        );
      }

      let newClassRooms = classRooms.map((classRoomItem) => {
        if (classRoomItem.id != classRoomId) {
          return classRoomItem;
        }

        const students = classRoomItem.students.map((studentItem, index) => {
          const firstName =
            inputClassStudent[indexClassRoomId][index].firstName;
          const lastName = inputClassStudent[indexClassRoomId][index].lastName;
          return { ...studentItem, firstName, lastName };
        });

        return { ...classRoomItem, students };
      });

      if (user.isAdmin()) {
        setUser({
          ...user,
          adminTeacher: { ...teacher, classRooms: newClassRooms },
        });
      } else {
        setUser({ ...user, classRooms: newClassRooms });
      }
    }
  };

  const inputValueChanged = (e, classId, studentId) => {
    const value = e.currentTarget.value;
    const inputName = e.currentTarget.name;

    const newInputClassStudent = inputClassStudent.map((classItem) => {
      const index = classItem.findIndex(
        (studentItem) => studentItem.id == studentId
      );
      if (index == -1) {
        return classItem;
      }
      return classItem.map((studentItem) => {
        if (studentItem.id != studentId) {
          return studentItem;
        }

        if (inputName == "firstName") {
          return { ...studentItem, firstName: value };
        } else if (inputName == "lastName") {
          return { ...studentItem, lastName: value };
        } else {
          return { ...studentItem };
        }
      });
    });

    setInputClassStudent(newInputClassStudent);
  };

  let container;
  if (!isLoading && user.isSignIn() && inputClassStudent.length > 0) {
    const classRoomInfoList = classRooms.map((classRoom, indexClass) => {
      const studentRows = classRoom.students.map((student, indexStudent) => {
        const studentUseInput = inputClassStudent[indexClass][indexStudent];
        if (studentUseInput == undefined) {
          return "";
        }
        console.log(
          studentUseInput,
          indexClass,
          indexStudent,
          inputClassStudent
        );
        return (
          <div key={studentUseInput.id}>
            <h4>Prénom de l'élève</h4>
            <input
              required
              type="text"
              name="firstName"
              value={studentUseInput.firstName}
              onChange={(e) => inputValueChanged(e, classRoom.id, student.id)}
            />
            <h4>initial du nom de famille de l'élève</h4>
            <input
              required
              type="text"
              name="lastName"
              value={studentUseInput.lastName}
              onChange={(e) => inputValueChanged(e, classRoom.id, student.id)}
            />
            <br />
            <hr />
          </div>
        );
      });
      return (
        <div
          className="embla__slide"
          style={{
            backgroundColor: Math.floor(Math.random() * 16777215).toString(16),
          }}
          key={classRoom.id}
        >
          <table>
            <thead>
              <tr>
                <td>
                  <button
                    onClick={() => {
                      emblaApi.scrollPrev();
                    }}
                  >
                    prev
                  </button>
                </td>
                <td>
                  {classRoom.grade} {classRoom.name} - code : ${classRoom.code}
                </td>
                <td>
                  <button
                    onClick={() => {
                      emblaApi.scrollNext();
                    }}
                  >
                    next
                  </button>
                </td>
              </tr>
            </thead>
          </table>
          <div>
            {studentRows}

            {!isTeacherLicence ? (
              <button onClick={() => updateStudentClassRoom(classRoom.id)}>
                Valider
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });

    let shouldDisplayClassRoomList = classRoomInfoList.length > 0;
    console.log(shouldDisplayClassRoomList);
    if (!shouldDisplayClassRoomList) {
      container = (
        <div>
          <h2>Vous n'avez inscrit aucun groupe pour le moment</h2>
          <button onClick={() => history.push(`${match.path}/addSchoolPopup`)}>
            Ajouter un groupe
          </button>
        </div>
      );
    } else {
      container = (
        <div>
          <div className="embla" ref={emblaRef}>
            <div className="embla__container">{classRoomInfoList}</div>
          </div>
          <button onClick={() => history.push(`${match.path}/addSchoolPopup`)}>
            Ajouter un groupe
          </button>
        </div>
      );
    }
  } else {
    container = (
      <div>
        <h2>Vous n'avez inscrit aucun groupe pour le moment</h2>
        <button onClick={() => history.push(`${match.path}/addSchoolPopup`)}>
          Ajouter un groupe
        </button>
      </div>
    );
  }

  return (
    <>
      <Route path={`${match.path}/addSchoolPopup`}>
        <ClassGenerationPostPopup redirectPath={match.path} />
      </Route>

      <Route
        path={`${match.path}/addStudent/:classRoomId`}
        children={<StudentGenerationPostPopup redirectPath={match.path} />}
      />

      <Route
        path={`${match.path}/editStudent/:classRoomId/:studentId`}
        children={<StudentGenerationPutPopup redirectPath={match.path} />}
      />

      <Route path={match.path}>
        <div className="addClassRoom">
          <h1 className="addClassRoom_title mediumTitlePurple">
            Ajouter des groupes
          </h1>

          <p className="addClassRoom_text">
            Avec votre abonnement vous pouvez ajouter jusqu'a {nbLicences}{" "}
            élèves au total. Vous avez {nbLicences - nbRemainedLicences} élèves.
            Chaque groupe représente un niveau, cela permmettra aux élèves
            d'avoir des exercices selon leur niveau.
          </p>
          {!isLoading ? container : ""}
        </div>
      </Route>
    </>
  );
}
