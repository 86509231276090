import React from 'react'

const ReportProgressBar= ({bgcolor,progress,height}) => {
	
	const Parentdiv = {
		height: 20,
		width: '100%',
		backgroundColor: '#EDE7F6',
		borderRadius: 40,
		margin: 10
	}
	
	const Childdiv = {
		height: '100%',
		width: `${progress}%`,
		backgroundColor: '#7E57C2',
	  borderRadius:40,
		textAlign: 'right'
	}
	
	const progresstext = {
		padding: 10,
		color: 'black',
		fontWeight: 900
	}
		
	return (
	<div style={Parentdiv}>
    <div style={Childdiv}>
    </div>
	</div>
	)
}

export default ReportProgressBar;
