import React from 'react';
import Title from '../../../../components/Text';
import SubscriptionInfo from './SubscriptionInfo.js';
import PayerInfo from './PayerInfo';

function SubscriptionPageAdmin() {
  return (
    <div className='subscriptionPageAdmin'>
      <div className='subscriptionPageAdmin_text subscriptionText'>
        <Title className='mediumTitlePurple subscriptionText_title' text='Abonnement'/>
        <p className='subscriptionText_subText'>
          Pour gérer votre abonnement, veuillez contacter le payeur. 
        </p>
      </div>
      <div className='subscriptionPageAdmin_detailSubscription'>
        <h5 className='SubscriptionInfo_title textGreyMedium'>Nature de l’abonnement</h5>
        <p className='SubscriptionInfo_text'>Abonnement annuel, pour <p className='numberOfStudent'>XX </p>élèves.</p>
      </div>
      <div className='subscriptionPageAdmin_subscriptionDates'>
        <SubscriptionInfo title='Date de souscription' text='7 septembre 2021' />
        <SubscriptionInfo title='Date de fin' text='6 septembre 2022' />
      </div>
      < PayerInfo payerFirstName='Gaston' payerLastName='Archibalde' payerEmail='archibalde.gaston@gmail.fr'/>
    </div>
  )
}

export default SubscriptionPageAdmin;