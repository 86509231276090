import React from "react";
import Title from "../../../../components/Text";
import ManagementProfText from "./ManagementProfsText";
import AddProfessorForm from "./AddProfessorForm";

function AddProfessor({
  submitForm,
  emailRef,
  firstNameRef,
  lastNameRef,
  nbRemainedLicences,
  nbLicences,
  zoneSelect,
  isLicenceTeacher,
}) {
  return (
    <div className="addProfessor">
      <ManagementProfText
        classNameTitle="mediumTitlePurple addProfessor_text"
        title="Inscrire un professeur"
        classNameText="textGreyMedium"
        text={`En inscrivant un professeur, 
        celui-ci recevra un e-mail avec des accès personnalisés au site web
        pour créer ses identifiants et inscrire sa/ses groupes/classe(s).`}
      />

      <AddProfessorForm
        submitForm={submitForm}
        innerEmailRef={emailRef}
        innerFirstNameRef={firstNameRef}
        innerLastNameRef={lastNameRef}
        nbRemainedLicences={nbRemainedLicences}
        zoneSelect={zoneSelect}
        isLicenceTeacher={isLicenceTeacher}
      />
    </div>
  );
}

//Vous pouvez inscrire ${nbLicences} professeurs max.

export default AddProfessor;
