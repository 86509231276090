import React from 'react';
import Title from '../../../../components/Text';

function ManagementProfText({title, classNameTitle, classNameText, text}) {
  return (
    <div className='managementProfText'>
      <Title className={classNameTitle} text={title} />
      <p className={classNameText}>{text}</p>
    </div>
  )
}

export default ManagementProfText;