import React from 'react';
import ManagementProfText from './ManagementProfsText';
import ListProfessor from './ListProfessors';
import AddProfessor from './AddProfessor';

function ManagementProf() {
  return (
    <div className='managementProf'>
      <ManagementProfText classNameTitle='mediumTitlePurple managementProf_title'
        title='Gestions des professeurs'
        classNameText='textGreyMedium'
        text='En tant qu’administrateur vous pouvez nommer les professeurs qui pourront accéder à lasolution Holy Owly avec leur(s) classe(s).' />
      <hr className='underLine'/>
      <ListProfessor />
      <AddProfessor />
    </div>
  )
}

export default ManagementProf;