import React, { useState } from 'react';


export default function useRadioButtons(name) {
    const [value, setState] = useState(null);

    const handleChange = e => {
        console.log(typeof e.target.value)
        setState(e.target.value);
    };

    let inputProps = {
        name,
        type: "radio",
        onChange: handleChange,
    };



    return [value, inputProps];
}