const MenuProfList = [
  {
    id: 'profStudentsMenu',
    title: 'Vos élèves',
    subPath: 'student',
    itemClassName: 'profMenuItem'
  },
  {
    id: 'profAddGroup',
    title: 'Ajouter des groupes',
    subPath: 'addGroup',
    itemClassName: 'profMenuItem'
  },
  {
    id: 'profInformation',
    title: 'Vos informations',
    subPath: 'info',
    itemClassName: 'profMenuItem'
  },
]

export default MenuProfList;