import React, { useContext, useRef } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import useZoneSelect from "../../../hook/useZoneSelect";
import useInput from "../../../hook/useInput";
import { UserContext } from "../../../app";

import AddProfessor from "../components/ManagementProfs/AddProfessor";

export default function TeacherGenerationPost({ teachersState }) {
  const firebaseInstance = getFirebase();
  const [teachers, setTeachers] = teachersState;

  const history = useHistory();

  const zoneSelect = useZoneSelect("A");
  const emailRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();

  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  const isLicenceTeacher = user.licenceType === "teacher";

  const submitForm = async (event) => {
    event.preventDefault();
    if (firebaseInstance) {
      try {
        const db = firebase.firestore();
        const docRef = db.collection("teacher");
        const newTeacherInfo = {
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          email: emailRef.current.value,
          classRooms: [],
          nbOfStudent: 0,
          adminUid: user.uid,
          licenceType: user.licenceType,
          zone: zoneSelect.value,
          admin: db.collection("admin").doc(user.id),
          creationDate: firebase.firestore.Timestamp.fromDate(new Date()),
        };
        const newTeacher = await docRef.doc();

        await newTeacher.set(newTeacherInfo);

        emailRef.current.value = "";
        firstNameRef.current.value = "";
        lastNameRef.current.value = "";

        // Update admin to add the teacher
        console.log(user.id);

        const newAdminTeacherList = [
          ...user.teachers,
          { ...newTeacherInfo, id: newTeacher.id },
        ];
        const adminCollection = db.collection("admin");
        const currentAdminDocRef = adminCollection.doc(user.id);
        let nbRemainedLicences = !user.isStudentLicence()
          ? (await user.getNumberOfRemainedLicence()) - 1
          : await user.getNumberOfRemainedLicence();

        const newAdminTeacherListRef = newAdminTeacherList.map((teacher) =>
          db.doc(`teacher/${teacher.id}`)
        );
        console.log(newAdminTeacherListRef);
        await currentAdminDocRef.set(
          { teachers: newAdminTeacherListRef, nbRemainedLicences },
          { merge: true }
        );
        setUser({ ...user, teachers: newAdminTeacherList, nbRemainedLicences });
      } catch (error) {
        alert(`An error occured: ${error}`);
        console.log("error", error);
      }
    }
  };

  return (
    <div>
      {/* <h2>Inscrire un profeseur</h2> */}
      {!isLoading ? (
        <AddProfessor
          submitForm={submitForm}
          emailRef={emailRef}
          firstNameRef={firstNameRef}
          lastNameRef={lastNameRef}
          nbRemainedLicences={user.nbRemainedLicences}
          nbLicences={user.nbLicences}
          zoneSelect={zoneSelect}
          isLicenceTeacher={isLicenceTeacher}
        />
      ) : (
        ""
      )}
    </div>
  );
}
