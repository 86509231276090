import React from 'react';

function StudentItem({studentId, studentName, onClick}) {
  return (
    <a href="#" onClick={onClick}>
    <div key={studentId} className =''>
      <div className=''>
        <p className='textOrangeMedium studentName'>
          <span>{studentName} </span>
          <span>code: {studentId}</span> </p>
        </div>
        
    </div>
    </a>
  )
}

export default StudentItem;