import React, { useEffect, useState, useCallback, useRef } from "react";
import ReactToPrint from "react-to-print";
import firebase from "firebase/app";
import ButtonPrint from "./buttonPrint";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import { useParams } from "react-router-dom";

export default function PrintClassPage() {
  let componentRef = useRef();
  const { classRoomId } = useParams();
  const firebase = getFirebase();
  const [isLoaded, setLoaded] = useState(false);
  const [classRoom, setClassRoom] = useState();
  const [students, setStudents] = useState([]);

  useEffect(() => {
    (async () => {
      setLoaded(false);
      if (!firebase) return;
      const db = firebase.firestore();

      const classDoc = await db.collection("classRooms").doc(classRoomId).get();
      if (classDoc.exists) {
        const data = classDoc.data();
        setClassRoom(data);
      }
    })();
  }, []);

  useEffect(() => {
    if (!classRoom) {
      return;
    }
    (async () => {
      const studentRefs = classRoom.students;
      let students = [];
      for (const studentRef of studentRefs) {
        let studentDoc = await studentRef.get();
        if (studentDoc.exists) {
          const studentData = studentDoc.data();
          students.push(studentData);
        }
      }
      setStudents(students);
      setLoaded(true);
    })();
  }, [classRoom]);

  const renderClassRoom = useCallback((classRoom, students, isLoaded) => {
    if (!isLoaded) {
      return <h1></h1>;
    }

    const grade = classRoom.grade;
    const codeClass = classRoom.code;

    const studentRender = students.map((student) => {
      return (
        <div className="studentEtiquette">
          <div className="studentEtiquette_infos studentEtiquetteInfos">
            <h4 className="studentEtiquetteInfos_firstName textEtiquette">
              {" "}
              Nom : {student.lastName}
            </h4>
            <h4 className="studentEtiquetteInfos_firstName textEtiquette">
              {" "}
              Prénom : {student.firstName}
            </h4>
            <h4 className="studentEtiquetteInfos_codeClass textEtiquette">
              {" "}
              Code classe : {codeClass}{" "}
            </h4>
            <h4 className="studentEtiquetteInfos_codeStudent textEtiquette">
              {" "}
              Code élève : {student.code}{" "}
            </h4>
          </div>
          <img
            className="studentEtiquette_img"
            src={require("../../../assets/images/pageSchool/illus-etiquettes-02.svg")}
          />
        </div>
      );
    });

    return (
      <div className="printPageWithButton">
        <ReactToPrint
          trigger={() => (
            <button className="printButton">impression étiquettes</button>
          )}
          content={() => componentRef}
        />
        <div className="pagePrintOut" ref={(el) => (componentRef = el)}>
          <h1 className="pagePrintOut_classTitle titlePurple">{grade}</h1>
          <h2 className="pagePrintOut_classTitle titlePurple">
            Code classe : {codeClass}
          </h2>
          {studentRender}
        </div>
      </div>
    );
  }, []);

  return <div>{renderClassRoom(classRoom, students, isLoaded)}</div>;
}
