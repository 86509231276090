import React from 'react';
import Button from '../../../../components/Button';
import LabelForm from '../../../Home/components/LabelForm';

function EditAdminInfos() {
  return(
    <div className="editAdminInfo">
      <form className='editAdminInfo_form'>
        <fieldset className='formeditAdminInfo'>
          <LabelForm labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
            labelClassName='adminInfo_label formInfo_infoLabel'
            labelText='Votre nom'
            inputClassName='adminInfo_input formInfo_infoInput'
            inputName='adminFirstName' />
          
          <LabelForm labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo' 
            labelClassName='adminInfo_label formInfo_infoLabel'
            labelText='Votre prénom'
            inputClassName='adminInfo_input formInfo_infoInput'
            inputName='adminLastName' />
          
          <LabelForm labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
            labelClassName='adminInfo_label formInfo_infoLabel'
            labelText='Votre téléphone'
            inputClassName='adminInfo_input formInfo_infoInput'
            inputName='adminTelephoneNumber' />
          
          <LabelForm labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
            labelClassName='adminInfo_label formInfo_infoLabel'
            labelText='Votre Votre adresse e-mail'
            inputClassName='adminInfo_input formInfo_infoInput'
            inputName='adminEmail' />
          
          <LabelForm labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
            labelClassName='adminInfo_label formInfo_infoLabel'
            labelText='Votre ancien mot de passe'
            inputClassName='adminInfo_input formInfo_infoInput'
            inputName='adminOldPassword' />
          
          <LabelForm labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
            labelClassName='adminInfo_label formInfo_infoLabel'
            labelText='Votre nouveau mot de passe'
            inputClassName='adminInfo_input formInfo_infoInput'
            inputName='adminNewPassword' />
          
          <Button className='buttonValidation' type='submit' text='ENREGISTRER'/>
          
        </fieldset>
      </form>

      <p className='editAdminInfo_warning'>Attention, votre nom, prénom et adresse e-mail seront transmis aux professeurs.</p>
    </div>
  )
}

export default EditAdminInfos;