import firebase from 'firebase/app'
import 'firebase/firestore'


const firebaseConfigDEV = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

const firebaseConfigPROD = {
    apiKey: "AIzaSyBl8pS_329FVERm7Bb_C0W_N-Vz35peKHY",
    authDomain: "holy-owly-v3.firebaseapp.com",
    databaseURL: "https://holy-owly-v3.firebaseio.com",
    projectId: "holy-owly-v3",
    storageBucket: "holy-owly-v3.appspot.com",
    messagingSenderId: "1033125632221",
    appId: "1:1033125632221:web:b579f89187b45feb9c2334",
    measurementId: "G-N1S931FJ96"
  };

let instance

export default function getFirebase() {
    if (typeof window !== "undefined") {
        if (instance) return instance
        instance = firebase.initializeApp(firebaseConfigPROD);
        return instance
    } r;

    return null
}