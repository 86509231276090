import React from "react";

function HeaderConnection() {
  return (
    <div className='headerOnlyLogo'>
      <img className='headerOnlyLogo_hoLogo' src={require('../../assets/images/homePage/logo-HOforSchool.svg')} />
      <h1 className='headerOnlyLogo_title titleWhite'>Connexion School</h1>
    </div>
  )
}

export default HeaderConnection;