const AboutList = [
  {
    title: 'L’histoire d’Holy Owly',
    url: '#',
    cName: 'aboutListLink'
  },
  {
    title: 'L’équipe',
    url: '#',
    cName: 'aboutListLink'
  },
  {
    title: 'Jobs',
    url: '#',
    cName: 'aboutListLink'
  },
  {
    title: 'Coin presse',
    url: '#',
    cName: 'aboutListLink'
  },
]

export default AboutList;