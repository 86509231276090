import React from "react";

function HolyOwlyApp() {
  return (
    <div className='holyOwlyApp'>
      <img className='holyOwlyApp_hoLogo' src={require('../../../assets/images/homePage/holyOwlyForSchool.svg')} />
      <div className='holyOwlyApp_downloadApp footerDownloadApp'>
        <h2 className='footerDownloadApp_text'>
          Télécharger l’application
        </h2>
          <div className='footerDownloadApp_downloadAppOs downloadAppOs'>
            <img className='downloadAppOs_android' src={require('../../../assets/images/homePage/googlePlay.svg')} />
            <img className='downloadAppOs_apple' src={require('../../../assets/images/homePage/appleStore.svg')} />
          </div>
      </div>
    </div>
  )
}

export default HolyOwlyApp;