const SiteMapList = [
  {
    title: 'Offres',
    url: '#',
    cName: 'siteMapLink'
  },
  {
    title: 'Blog',
    url: '#',
    cName: 'siteMapLink'
  },
  {
    title: 'Enseignants',
    url: '#',
    cName: 'siteMapLink'
  },
  {
    title: 'Obtenir de l’aide',
    url: '#',
    cName: 'siteMapLink'
  },
]

export default SiteMapList;