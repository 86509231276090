import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import useGradeSchoolSelect from "../../../hook/useGradeSchoolSelect";
import useRadioButtons from "../../../hook/useRadioButtons";
import useInput from "../../../hook/useInput";
import generatePassword from "password-generator";
import classRoomExtension from "../../../extension/classRoomExtension";
import EditStudent from "../components/EditStudent";
import useScrollBlock from "../../../hook/useScrollBlock";

export default function StudentGenerationPutDeletePopup({ redirectPath }) {
  const firebaseInstance = getFirebase();
  const history = useHistory();
  const { classRoomId, studentId } = useParams();
  // context
  const { useUser, isLoading } = useContext(UserContext);

  const [user, setUser] = useUser;

  const classRooms = user.isAdmin()
    ? user.adminTeacher.classRooms
    : user.classRooms;
  const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
  const teacher = user.isAdmin() ? user.adminTeacher : user;

  const classRoom = classRooms.find((classRoom) => classRoom.id == classRoomId);
  const student = classRoom.students.find((student) => student.id == studentId);
  const admin = user.isAdmin() ? user : user.admin;
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // hooks
  const useFirstName = useInput(student.firstName);
  const useLastName = useInput(student.lastName);

  const deleteStudentAction = useCallback(
    async (studentId) => {
      setIsLoadingRequest(true);
      const db = firebase.firestore();
      const ref = db.collection("student");
      const docAdminRef = db.collection("admin").doc(admin.uid);
      try {
        await ref.doc(studentId).delete();

        const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
        const teacher = user.isAdmin() ? user.adminTeacher : user;

        const classRoom = classRooms.find((classRoomItem) => {
          return classRoomItem.id == classRoomId;
        });
        if (classRoom == undefined) {
          history.push(redirectPath);
          return;
        }

        let newListOfStudent = classRoom.students.filter(
          (studentItem) => studentItem.id != studentId
        );

        if (newListOfStudent === undefined) {
          newListOfStudent = [];
        }

        const db = firebase.firestore();
        const classRoomCollection = db.collection("classRooms");
        const currentClassRoomDocRef = classRoomCollection.doc(classRoomId);

        const newClassRoomStudentListRef = newListOfStudent.map((student) =>
          db.doc(`student/${student.id}`)
        );

        const data = {
          students: newClassRoomStudentListRef,
        };

        await currentClassRoomDocRef.set(data, { merge: true });

        const classRoomUpdated = classRooms.map((classRoomItem) => {
          if (classRoomItem.id != classRoomId) {
            return { ...classRoomItem };
          }
          return { ...classRoomItem, students: newListOfStudent };
        });

        let nbRemainedLicences = admin.nbRemainedLicences;
        if (admin.isStudentLicence()) {
          nbRemainedLicences = await user.getNumberOfRemainedLicence();
          await docAdminRef.set({ nbRemainedLicences }, { merge: true });
        }

        history.push(redirectPath);

        if (user.isAdmin()) {
          setUser({
            ...user,
            adminTeacher: {
              ...teacher,
              classRooms: classRoomUpdated,
            },
            nbRemainedLicences,
          });
        } else {
          setUser({
            ...user,
            classRooms: classRoomUpdated,
            nbRemainedLicences,
            admin: {
              ...user.admin,
              nbRemainedLicences,
            },
          });
        }
      } catch (error) {
        console.error("Error removing document: ", error);
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [setIsLoadingRequest, setUser]
  );

  const submitForm = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoadingRequest(true);
      const firstName = useFirstName.value;
      const lastName = useLastName.value;
      const classRoom = classRooms.find(
        (classRoomItem) => classRoomItem.id == classRoomId
      );

      console.log(classRoom);

      if ((firebaseInstance, user.isSignIn())) {
        try {
          const db = firebase.firestore();
          const docRef = db.collection("student");
          const newStudentInfo = {
            firstName,
            lastName,
            classRoom: db.collection("classRooms").doc(classRoomId),
          };

          const studentDoc = await docRef.doc(studentId);

          await studentDoc.set(newStudentInfo, { merge: true });

          const newStudentClassRoomList = classRoom.students.map(
            (studentItem) => {
              if (studentItem.id != studentId) {
                return studentItem;
              } else {
                return { ...student, ...newStudentInfo, id: studentDoc.id };
              }
            }
          );

          const classRoomCollection = db.collection("classRooms");
          const currentClassRoomDocRef = classRoomCollection.doc(classRoom.id);
          const newStudentClassRoomListRef = newStudentClassRoomList.map(
            (classRoomMapItem) => db.doc(`student/${classRoomMapItem.id}`)
          );
          await currentClassRoomDocRef.set(
            { students: newStudentClassRoomListRef },
            { merge: true }
          );

          const newClassRooms = classRooms.map((classRoomItem) => {
            if (classRoomItem.id != classRoomId) {
              return classRoomItem;
            }
            return classRoomExtension({
              ...classRoomItem,
              students: newStudentClassRoomList,
            });
          });

          if (user.isAdmin()) {
            setUser({
              ...user,
              adminTeacher: { ...teacher, classRooms: newClassRooms },
            });
          } else {
            setUser({ ...user, classRooms: newClassRooms });
          }

          history.push(redirectPath);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    },
    [setUser, setIsLoadingRequest, useFirstName.value, useLastName.value]
  );

  return (
    <div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          opacity: "0.8",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          top: "0",
          left: "0",
          "z-index": "99999",
        }}
      />
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "0",
          left: "25%",
          display: "flex",
          flexDirection: "column",
          "z-index": "99999",
        }}
      >
        <form onSubmit={submitForm}>
          <EditStudent
            isLoadingRequest={isLoadingRequest}
            code={student.code}
            deleteOnClick={() => deleteStudentAction(studentId)}
            closeOnClick={() => history.push(redirectPath)}
            useFirstNameInput={useFirstName}
            useLastNameInput={useLastName}
          />
          {/* <h2>Prénom de l'élève</h2>
                    <input required type="text" name="name" {...useFirstName} />
                    <h2>initial du nom de famille de l'élève</h2>
                    <input required type="text" name="name" {...useLastName} /><br />
                    <button type="submit">Envoyer</button>
                    <button type="submit" onClick={() => history.push(redirectPath)}>close</button>
                    <button type="button" onClick={() => deleteStudentAction(studentId)}>delete</button>
                    <p>code {student.code}</p> */}
        </form>
      </div>
    </div>
  );
}
