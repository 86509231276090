import React, { useState, useEffect, useContext, useRef, useMemo, memo } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
} from "react-router-dom";
import { UserContext } from "../../../app"
import firebase from 'firebase/app'
import 'firebase/firestore'
import getFirebase from "../../../utils/firebase"
import useInput from "../../../hook/useInput";
import Button from '../../../components/Button';
import LabelForm from '../../../components/InputLabelForm';


export default function AdminGenerationPut({ adminState }) {

    let match = useRouteMatch()
    // context
    const { useUser, isLoading } = useContext(UserContext)
    const [user, setUser] = useUser
    const firebaseInstance = getFirebase()

    const firstNameVal = (user.isAdmin()) ? user.firstName : user.intendantPrenom;
    const lastNameVal = (user.isAdmin()) ? user.lastName : user.intendantNom;

    const firstName = useInput(firstNameVal)
    const lastName = useInput(lastNameVal)
    const phone = useInput(user.phone)
    const password = useInput()
    const confirmPassword = useInput()


    useEffect(() => {
        console.log(user.isSignIn())
        if (!isLoading && user.isSignIn()) {
            console.log(user)
            firstName.setValue(firstNameVal)
            lastName.setValue(lastNameVal)
            phone.setValue(user.phone)
        }
    }, [isLoading]);

    const submitForm = async (event) => {
        event.preventDefault()
        if (firebaseInstance) {
            try {
                const db = firebase.firestore();
                const adminCollection = db.collection("admin")
                const currentAdminDocRef = adminCollection.doc(user.id);

                const firstNameValue = firstName.value
                const lastNameValue = lastName.value
                const phoneValue = phone.value
                const passwordValue = password.value
                const confirmPasswordValue = confirmPassword.value

                const data = {
                    firstName: firstNameValue ?? "",
                    lastName: lastNameValue ?? "",
                    phone: phoneValue ?? ""
                }

                await currentAdminDocRef.set(data, { merge: true });
                setUser({ ...user, ...data })

                if (passwordValue != undefined && passwordValue != "" && confirmPasswordValue != undefined && confirmPasswordValue != "") {
                    if (passwordValue != confirmPasswordValue) {
                        alert("Veuillez utilisez le même mot de passe pour les deux champs")
                        return
                    }
                    const userFirebase = firebase.auth().currentUser;
                    await userFirebase.updatePassword(password.value)
                    alert("Votre mot de passe vient d'être changé")
                }

            } catch (err) {
                console.error(err);
                if (err.code == "auth/requires-recent-login") {
                    alert("Vous devez vous reconnecter pour changer le mot de passe")
                }

                if (err.code =="auth/weak-password") {
                    alert("Le mot de passe doit faire plus de 6 caractères")
                }
            }
        }
    };

    

    return (
        <div div className="editAdminInfo">
            {(!isLoading) ? ((
            <>
            <form onSubmit={submitForm} className='editAdminInfo_form'>
            <fieldset className='formeditAdminInfo'>
                <LabelForm 
                    labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
                    labelClassName='adminInfo_label formInfo_infoLabel'
                    labelText='Votre nom'
                    inputClassName='adminInfo_input formInfo_infoInput'
                    placeholder="Ajouter votre prenom"
                    inputName='adminFirstName'
                    userInput={firstName} 
                />
                
                <LabelForm 
                    labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo' 
                    labelClassName='adminInfo_label formInfo_infoLabel'
                    labelText='Votre prénom'
                    inputClassName='adminInfo_input formInfo_infoInput'
                    placeholder="Ajouter votre nom"
                    inputName='adminLastName'
                    userInput={lastName} 
                />
                
                <LabelForm 
                    labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
                    labelClassName='adminInfo_label formInfo_infoLabel'
                    labelText='Votre téléphone'
                    inputClassName='adminInfo_input formInfo_infoInput'
                    placeholder="ajouter votre telephone"
                    inputName='adminTelephoneNumber'
                    userInput={phone}
                 />
                
                <LabelForm 
                    labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
                    labelClassName='adminInfo_label formInfo_infoLabel'
                    labelText='Nouveau mot de passe'
                    inputClassName='adminInfo_input formInfo_infoInput'
                    placeholder="Ajouter un nouveau mot de passe"
                    inputName='adminOldPassword'
                    inputType="password"
                    userInput={password} 
                />
                
                <LabelForm 
                    labelFormClassName='formeditAdminInfo_adminInfo adminInfo formInfo'
                    labelClassName='adminInfo_label formInfo_infoLabel'
                    labelText='Confirmation nouveau mot de passe'
                    inputClassName='adminInfo_input formInfo_infoInput'
                    placeholder="Confirmer votre nouveau mot de passe"
                    inputName='adminNewPassword'
                    inputType="password"
                    userInput={confirmPassword} 
                />
                
                <Button className='buttonValidation' type='submit' text='ENREGISTRER'/>
                
            </fieldset>
            </form>
            
            <p className='editAdminInfo_warning'>Attention, votre nom, prénom et adresse e-mail seront transmis aux professeurs.</p>
            </>
            )) : ""}
        </div>



    )
}
