import React from "react";

const Loader = () => {
  return <div class="loadingio-spinner-spinner-vj9pt0z1qv">
    <div class="ldio-nwnenb082zl">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>;
};

export default Loader;
