import React, { useState, useEffect, useContext, useRef } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useRouteMatch,
} from "react-router-dom";
import firebase from 'firebase/app'
import 'firebase/firestore'
import getFirebase from "../../../utils/firebase"
import { UserContext } from "../../../app"
import useGradeSchoolSelect from "../../../hook/useGradeSchoolSelect"
import useRadioButtons from "../../../hook/useRadioButtons"
import useInput from "../../../hook/useInput"
import classRoomExtension from '../../../extension/classRoomExtension'
import generator from 'generate-password-browser';
import AddClass from '../components/AddClass';
import useScrollBlock from "../../../hook/useScrollBlock"

export default function ClassGenerationPostPopup({ redirectPath }) {

    const firebaseInstance = getFirebase()
    const history = useHistory();
    // context
    const { useUser, isLoading } = useContext(UserContext)

    // hooks
    const useHasGuide = useRadioButtons("hasGuide", "true");
    const [hasGuide, guideProps] = useHasGuide
    const useName = useInput("")
    const [user, setUser] = useUser
    const isFromHO = user.isAdminFromHO()
    const useGradeSchool = useGradeSchoolSelect("CP", isFromHO)

    // const [blockScroll, allowScroll] = useScrollBlock()
    //
    // useEffect(() => {
    //     blockScroll()
    //     return () => {
    //       allowScroll()
    //     };
    // }, []);

    const submitForm = async (event) => {
        event.preventDefault()

        const grade = useGradeSchool.value
        const name = useName.value
        const hasGuideVal = hasGuide == "true" ? true : false

        if (firebaseInstance, user.isSignIn()) {
            try {
                const db = firebase.firestore();
                const docRef = db.collection("classRooms");
                const userId = (user.isAdmin()) ? user.adminTeacher.id : user.id
                const classRooms = (user.isAdmin()) ? user.adminTeacher.classRooms : user.classRooms
                const teacher = (user.isAdmin()) ? user.adminTeacher : user

                let newClassRoom
                let newClassRoomInfo
                let doc
                do {
                    const code = generator.generate({
                        length: 6,
                        numbers: true,
                        lowercase: false,
                        uppercase: false
                    });

                    newClassRoomInfo = {
                        grade,
                        name,
                        hasGuide: hasGuideVal,
                        code,
                        zone: teacher.zone,
                        uidTeacher: teacher.id,
                        students: []
                    }

                    newClassRoom = await docRef.doc(code)
                    doc = await newClassRoom.get();

                } while(doc.exists)

                await newClassRoom.set(
                    newClassRoomInfo
                );

                const newTeacherClassRoomList = [...classRooms, { ...newClassRoomInfo, id: newClassRoom.id }]
                const teacherCollection = db.collection("teacher")
                const currentTeacherDocRef = teacherCollection.doc(userId);
                const newTeacherClassRoomListRef = newTeacherClassRoomList.map((classRoom) => db.doc(`classRooms/${classRoom.id}`))
                console.log(newTeacherClassRoomListRef)
                await currentTeacherDocRef.set({ classRooms: newTeacherClassRoomListRef }, { merge: true });

                if (user.isAdmin()) {
                    setUser({ ...user, adminTeacher: { ...teacher, classRooms: newTeacherClassRoomList.map((classRoomInfo) => classRoomExtension(classRoomInfo)) } })
                } else {
                    setUser({ ...teacher, classRooms: newTeacherClassRoomList.map((classRoomInfo) => classRoomExtension(classRoomInfo)) })
                }


                history.push(redirectPath);
            } catch (error) {
                console.log(error)
            }
        }
    }

    const closeAction = () => {
        history.push(redirectPath);
    }

    const handleChangeSelect = (event) => {
        const val = event.target.value
        useGradeSchool.setValue(event.target.value);
    };

    return (
        <div className='popUpAddClass'>
            <div style={{
                'position': 'absolute',
                'backgroundColor': 'black',
                'opacity': '0.8',
                'width': '100vw',
                'height': '100%',
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'center',
                'justifyContent': 'center',
                'top': '0',
                'left': '0',
                'z-index': '99999'
            }} />
            <div style={{
                'position': 'absolute',
                'textAlign': 'center',
                'backgroundColor': 'white',
                'display': 'flex',
                'flexDirection': 'column',
                'z-index': '99999',
                'top': '10%',
                'left': '30%',
            }} >
                <form onSubmit={submitForm}>
                    <AddClass
                        handleChangeSelect={handleChangeSelect}
                        closeOnClick={closeAction} 
                        useName={useName}
                        useGradeSchool={useGradeSchool}
                        useHasGuide={useHasGuide}
                    />
                </form>
            </div>
        </div >



    )
}
