import React from "react";
import LabelForm from "../../Home/components/LabelForm";
import Button from "../../../components/Button";
import DeleteButton from "../../../components/DeleteButton";

//the style is using the same className like in the component ** adminMenu **

function AddClass({handleChangeSelect, closeOnClick, useName, useGradeSchool, useHasGuide}) {
  
  const [hasGuide, guideProps] = useHasGuide

  return (
    <div style={{backgroundColor: "white"}} className='editClass'>
      <a href="#" onClick={closeOnClick}>
        <img className='editClass_buttonClose' src={require('../../../assets/images/homePage/iconClose.svg')} />
      </a>
      <h2 className='editClass_title mediumTitleOrange'>Fiche classe</h2>
      <p className='editClass_subTitle'>Pour plus de détails, rendez-vous sur l’application Holy Owly School.</p>
      <form className='formAddstudentClass'>
        <label className='formAddstudentClass_label'>Classe</label>
        <select  name="class" className='addStudentClassInput' required value={useGradeSchool.value} onChange={handleChangeSelect}>
                        {
                            useGradeSchool.schoolGrade.map((schoolGrade) => {
                                return (<option className='selectValue' key={schoolGrade} value={schoolGrade}>{schoolGrade}</option>)
                            })
                        }
                    </select>
      </form>
      <LabelForm labelFormClassName='formAddstudentClass'
        labelClassName='formAddstudentClass_label'
        labelText='Nom du groupe'
        inputClassName='addStudentClassInput'
        inputName='className'
        inputPlaceholder='PCP 01'
        useInput={useName}
        required={true}
      />

      {/* <p className="editClass_guideCoiceText">Vous avez acheté un guide pédagogique :</p>

      <div className='editClass_guideChoice guideCoice'>
        <div className='guideCoice_choice'>
        <input required type="radio" value="true" {...guideProps} />
          <label>
            Oui
          </label>
        </div>

        <div className='guideCoice_choice'>
        <input required type="radio" value="false" {...guideProps} />
          <label>
            Non
          </label>
        </div>
      </div> */}

      <div className='editStudent_editButtons'>
        <Button type="submit" className='buttonPopUpPurple' text='ENREGISTRER'/>
      </div>
    </div>
  )
}

export default AddClass;