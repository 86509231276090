import React from "react";
import { Link, useRouteMatch } from "react-router-dom";

function ProblemConnection() {

  const match = useRouteMatch()

  return (
    <div class='problemConnection'>
      <div className='problemConnection_accountProblem'>
        <p>
          Pas encore de compte ?
        </p>
        <Link to={`${match.path}/password`}>
          <p>
            Mot de passe oublié ?
          </p>
        </Link>
      </div>
      <p className='problemConnection_addLicence'>
        Ajouter des licences à mon compte
      </p>
    </div>
  )
}

export default ProblemConnection;