import Title from "../../components/Text";
import ManagementProf from "./components/ManagementProfs/ManagementProfs";
import ManagementProfText from "./components/ManagementProfs/ManagementProfsText";
import AddProfessor from "./components/ManagementProfs/AddProfessor";
import EditAdminInfos from "./components/AdminInfos/EditAdmininfos";
import Header from "../../components/Header/Header";
import AdminMenu from "./components/AdminMenu";
import SubscriptionPageAdmin from "./components/Subscription/SubscriptionPageAdmin";
import Footer from "../../components/Footer/Footer";
import AdminInfo from "./components/AdminInfos/AdminInfos.js";
import HeaderOnlyLogo from "../../components/Header/HeaderOnlyLogo";
import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import TeacherGeneration from "./teacherGeneration/TeacherGeneration";
import AdminGenerationGet from "./adminGeneration/AdminGenerationGet";
import AdminSubcription from "./AdminSubcription";

import { UserContext } from "../../app";
import StudentGenerationGetDelete from "../Teacher/studentGeneration/StudentGenerationGetDelete";
import ClassGenerationGetPut from "../Teacher/classGeneration/ClassGenerationGetPut";
import TeacherGenerationPut from "../Teacher/teacherGeneration/TeacherGenerationPut";
import ClassGenerationPostPopup from "../Teacher/classGeneration/ClassGenerationPostPopup";
import ChoiceAdminType from "./choiceAdminType/ChoiceAdminType";
import ClassStudentGenerationPost from "../teacher/classStudentGeneration/ClassStudentGenerationPost";
import StudentReport from "../teacher/studentGeneration/StudentReport";

function AdminHome() {
  const match = useRouteMatch();
  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  const adminHome = (
    <>
      <Header />
      <div className="pageAdmin">
        <Title
          className="titlePurple  pageAdmin_title"
          text="Bienvenue dans votre espace <br/>
        administrateur !"
        />
        <AdminMenu />
        <Switch>
          <Route path={`${match.path}/info`}>
            <AdminGenerationGet />
          </Route>
          <Route path={`${match.path}/subscription`}>
            <AdminSubcription />
          </Route>
          <Route
            path={`${match.path}/student/studentReport/:studentId`}
            children={<StudentReport redirectPath={`${match.path}/student`} />}
          />
          <Route path={`${match.path}/student`}>
            <StudentGenerationGetDelete />
          </Route>
          <Route path={`${match.path}/addGroup`}>
            <ClassStudentGenerationPost />
          </Route>
          <Route path={match.path}>
            <TeacherGeneration />
          </Route>
        </Switch>
      </div>
      <Footer />
    </>
  );

  const choiceAdminType = (
    <>
      <HeaderOnlyLogo />
      <ChoiceAdminType />
    </>
  );

  return <>{user.isFirstTimeChoiceAdminType() ? choiceAdminType : adminHome}</>;
}

export default AdminHome;
