import React from "react";

//the style is using the same className like in the component ** adminMenu **

function InputLabelForm({labelClassName, labelText, inputClassName, inputName,placeholder, userInput, inputType}) {
  return (
    <div className='inputLabelForm'>
      <label className={labelClassName} >
        {labelText }
        <input type={(inputType !== undefined) ? inputType : "text"} className={inputClassName} name={inputName} placeholder={placeholder} {...userInput}/>
      </label>
    </div>
  )
}

export default InputLabelForm;
