import React from 'react';
import styles from '../styles/style.scss';

function createText(text) {
  return { __html: text}
}

function Title({text, className}) {
  return <h1 className={className} dangerouslySetInnerHTML={ createText(text) }/>
}

export default Title;