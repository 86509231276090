import React from 'react';
import SubscriptionInfo from './SubscriptionInfo.js';

function PayerInfo({payerFirstName, payerLastName, payerEmail}) {
  return (
    <div className='payerInfo'>
      <SubscriptionInfo title='Nom payeur' text={ payerLastName }/>
      <SubscriptionInfo title='Prénom payeur' text={payerFirstName} />
      <SubscriptionInfo title='E-mail' text={ payerEmail }/>
    </div>
  )
}
export default PayerInfo;