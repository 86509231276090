import React, { useEffect, useState, useContext } from 'react'
import getFirebase from "../../utils/firebase"
import 'firebase/auth';
import firebase from 'firebase/app'
import 'firebase/firestore'
import useInput from '../../hook/useInput'
import { UserContext } from "../../app"
import Header from '../../components/Header/Header';

import {
    BrowserRouter as Router,
    useHistory,
} from "react-router-dom";

export default function resetPassword() {
    const firebaseInstance = getFirebase()
    const history = useHistory();
    const db = firebase.firestore();
    const auth = firebase.auth()
    // context
    const { useUser, isLoading } = useContext(UserContext)

    const [user, setUser] = useUser


    let userTypeName
    if (!isLoading && user != null) {
        userTypeName = user.isAdmin() ? "Administrateur" : "Professeur"
    }

    // Ref
    const emailInput = useInput("");

    const changePassword = async (e) => {
        e.preventDefault()
        const emailValue = emailInput.value
        try {
          await auth.sendPasswordResetEmail(emailValue)
          alert("Un email a été envoyé dans votre boîte mail")
        } catch(error) {
          console.log(error)
        }
    }

    return (
      <div className='resetPassword'>
        <Header />
        <h2 className='resetPassword_title'>Mot de passe oublié</h2>
        <form className="resetPassword_form formResetPassword" onSubmit={changePassword}>
          <label className='formResetPassword_label loginForm_label'>E-MAIL</label>
          <input className='formResetPassword_input loginForm_input 'type="email" required placeholder="Veuillez saisir votre email" {...emailInput}/>
          <button className='formResetPassword_button buttonValidation' type="submit">ENVOYER</button>
        </form>
      </div>
    )
}