import React, {useContext} from "react";
import { UserContext } from "./../../../app"
import getFirebase from "../../../utils/firebase"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";

function IntroChoiceAdminType({ name }) {
  const { useUser, isLoading } = useContext(UserContext)
  const [user, setUser] = useUser

  const match = useRouteMatch();
  const firebase = getFirebase();

  const logoutClicked = async () => {
    setUser(null)
    await firebase.auth().signOut()
  }

  return (
    <div className='introChoiceAdminType'>
      <h1 className='introChoiceAdminType_title titlePurple'>Bienvenue sur Holy Owly School !</h1>
      <div className='introChoiceAdminType_text'>
        <p>Bonjour {name}, et bienvenue sur Holy Owly School.
        </p>
        <p>
          Pour affiner votre tableau de bord, veuillez choisir votre profil : 
        </p>
      </div>
      {/* <button className='introChoiceAdminType_buttonLogOut' onClick={async () => logoutClicked()}>Déconnexion</button> */}
    </div>
  )
}

export default IntroChoiceAdminType;