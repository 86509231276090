import { useState, useEffect } from "react";
import "firebase/auth";
import "firebase/firestore";
import getFirebase from "../utils/firebase";
import userExtension from "../extension/userExtension";
import classRoomExtension from "../extension/classRoomExtension";

export default function useUser() {
  const firebase = getFirebase();

  // Hooks
  const [userAuth, setUserAuth] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // UseStates

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setUserAuth(user);
      } else {
        setUserAuth(null);
        setUser(null);
        setIsLoading(false);
      }
    });
    return () => {
      console.log("removed ??");
    };
  }, []);

  useEffect(() => {
    const updateUser = async () => {
      if (userAuth != null) {
        const userUID = userAuth.uid;
        let user;
        user = await getAdmin(userUID);
        if (user === null) {
          user = await getTeacher(userUID);
        } else if (user === null) {
          setUser(null);
          setIsLoading(false);
          return;
        }

        setUser(user);
        setIsLoading(false);
      }
    };

    updateUser();
  }, [userAuth]);

  // Helpers
  const getAdmin = async (uid) => {
    try {
      if (!firebase) return;
      const db = firebase.firestore();
      const ref = db.collection("admin").where("uid", "==", uid);

      const querySnapshot = await ref.get();

      if (!querySnapshot.empty) {
        const admin = querySnapshot.docs[0].data();

        let adminTeacher;
        if (admin.adminTeacher != undefined) {
          adminTeacher = await getTeacher(uid);
        }

        let teachers = [];
        for (const teacher of admin.teachers) {
          let teacherDoc = await db.collection("teacher").doc(teacher.id).get();
          if (teacherDoc.exists) {
            const teacherData = teacherDoc.data();

            let classRooms = [];
            for (const classRoom of teacherData.classRooms) {
              let classRoomDoc = await db
                .collection("classRooms")
                .doc(classRoom.id)
                .get();
              if (classRoomDoc.exists) {
                const classRoomData = classRoomExtension(classRoomDoc.data());
                let students = [];
                for (const student of classRoomData.students) {
                  let studentDoc = await db
                    .collection("student")
                    .doc(student.id)
                    .get();
                  if (studentDoc.exists) {
                    const studentData = studentDoc.data();
                    students.push({ ...studentData, id: studentDoc.id });
                  }
                }

                classRooms.push({
                  ...classRoomData,
                  id: classRoomDoc.id,
                  students,
                });
              }
            }

            teachers.push({
              ...teacherData,
              type: "teacher",
              id: teacher.id,
              classRooms,
            });
          }
        }

        return {
          ...admin,
          adminTeacher,
          type: "admin",
          id: querySnapshot.docs[0].id,
          teachers,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTeacher = async (uid) => {
    try {
      if (!firebase) return;
      const db = firebase.firestore();
      const ref = db.collection("teacher").where("uid", "==", uid);

      const querySnapshot = await ref.get();

      if (!querySnapshot.empty) {
        const teacher = querySnapshot.docs[0].data();

        const adminDoc = await db
          .collection("admin")
          .doc(teacher.admin.id)
          .get();
        let admin;
        if (adminDoc.exists) {
          admin = adminDoc.data();
        }

        let classRooms = [];
        for (const classRoom of teacher.classRooms) {
          let classRoomDoc = await db
            .collection("classRooms")
            .doc(classRoom.id)
            .get();
          if (classRoomDoc.exists) {
            const classRoomData = classRoomExtension(classRoomDoc.data());
            let students = [];
            //console.log(classRoom)
            for (const student of classRoomData.students) {
              let studentDoc = await db
                .collection("student")
                .doc(student.id)
                .get();
              if (studentDoc.exists) {
                const studentData = studentDoc.data();
                students.push({ ...studentData, id: studentDoc.id });
              }
            }

            classRooms.push({
              ...classRoomData,
              id: classRoomDoc.id,
              students,
            });
          }
        }

        return {
          ...teacher,
          type: "teacher",
          id: querySnapshot.docs[0].id,
          classRooms,
          admin: userExtension(admin),
        };
      } else {
        return null;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return {
    useUser: [userExtension(user), setUser],
    isLoading,
  };
}
