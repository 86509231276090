import React from "react";
import ReportProgressBar from "./ReportProgressBar";

export default function ReportProgram({ prog, progStat }) {
  const successPercentageChild = progStat.successRate;
  const progName = prog.nom;
  return (
    <div className="ReportProgram">
      <div></div>
      <div className="ReportProgram_details reportProgramDetails">
        <h3 className="reportProgramDetails_programName programName smalltitlePurple">
          {progName}
        </h3>
        <div className="reportProgramDetails_successPercentage succesPercentage">
          <ReportProgressBar progress={successPercentageChild} />
          <h4 className="succesPercentage_text smalltitlePurple ">
            {successPercentageChild}%
          </h4>
        </div>
      </div>
    </div>
  );
}

