
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";

export default function ButtonPrint() {
  let componentRef = useRef();

  return (
    <>
      <div>
      </div>
    </>
  );
}