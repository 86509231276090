import React from 'react';
import styles from '../../../styles/style.scss';
import Button from '../../../components/Button';

function createText(text) {
  return { __html: text}
}

function ArticlePageSchoolTitle({ title }) {
  return <h3 className='schoolSectionTitle'>{title}</h3>
}

function SimpleArticle({ text }) {
  return <p dangerouslySetInnerHTML={ createText(text) }/>
}

function FirstArticle() {
  return (
    <div className='pageSchoolFirstArticle'>
      <div className='pageSchoolFirstArticle_text'>
        <ArticlePageSchoolTitle title='Une solution clé en main pour les classes' />
        <ul className='articleWithList'>
          <li>
          Une application intuitive ne nécessitant aucune préparation de séance.
          </li>

          <li>
            5 minutes par jour d'entraînement pour favoriser la prosodie anglaise.
          </li>

          <li>
            Un travail individuel sur tablette ou collectif via vidéoprojection.
          </li>

          <li>
            Une pratique de l’oral facile pour l'enfant grâce à un système de reconnaissance vocale.
          </li>

          <li>
            Un tableau de bord pour l’enseignant pour visualiser les progrès des élèves.
          </li>

          <li>
          Un contenu pédagogique adapté de la PS au CM2 pour la mise en place d’un projet d’école progressif et structuré !
          </li>
        </ul>
        <a href='https://www.editions-bordas.fr/recherche?text=holy+owly'>
          <Button className='buttonOrangeMedium pageschoolButton' text='ACHETER DES LICENCES'/>
        </a>
        
      </div>
      <img className='pageSchoolFirstArticle_classRoomImage' src={require('../../../assets/images/pageSchool/ho-school-accueil-enfant.png')} />
    </div>
  )
}

function SecondArticle() {
  return (
    <div className='pageSchoolSecondArticle'>
      <div className='pageSchoolSecondArticle_appImages appImageIcon'>
        <img className='appImageIcon_iconOwly' src={require('../../../assets/images/pageSchool/icon-iphone-owly-school.png')} />
        <img className='appImageIcon_appImage' src={require('../../../assets/images/pageSchool/ho-school-app.png')} />
      </div>
      <div className='pageSchoolSecondArticle_text'>
        <ArticlePageSchoolTitle title='Une méthode d’anglais en 7 étapes quotidiennes' />
        <ul className='articleWithList'>
          <li>
            <h4 class='articleListWithTitle'>
              1 - Découverte des mots
            </h4>
            <p>
            L’élève découvre les 3 mots ou 3 phrases qu’il va apprendre dans sa séance quotidienne à l’aide de sons, d’images ou de textes.
            </p>
          </li>

          <li>
            <h4 class='articleListWithTitle'>
              2 - Expression orale
            </h4>
            <p>
              Grâce à la reconnaissance vocale intégrée à l’application, l’élève répète les mots ou les phrases en toute autonomie.
            </p>
          </li>

          <li>
            <h4 class='articleListWithTitle'>
              3 - Compréhension orale
            </h4>
            <p>
              L’élève travaille la compréhension orale à travers une activité de discrimination auditive.
            </p>
          </li>

          <li>
            <h4 class='articleListWithTitle'>
              4 - Exercice de tri
            </h4>
            <p>
              L’élève doit classer les images en fonction des mots qu’il entend.
            </p>
          </li>

          <li>
            <h4 class='articleListWithTitle'>
              5 - Travail de mémorisation
            </h4>
            <p>
            L’élève doit soit remettre des images dans le bon ordre, soit reformer des phrases en déplaçant des mots.
            </p>
          </li>

          <li>
            <h4 class='articleListWithTitle'>
            6 - Jeux
            </h4>
            <p>
              Chaque jour, l’élève découvre un jeu différent (12 mini-jeux : labyrinthe, contre la montre, …).
            </p>
          </li>

          <li>
            <h4 class='articleListWithTitle'>
              7 - Vérification
            </h4>
            <p>
              L’élève vérifie qu’il a bien intégré le vocabulaire, et répète pour valider la prononciation.
            </p>
          </li>
        </ul>          
      </div>
    </div>
  )
}

function ThirdArticle() {
  return (
    <div className='pageSchoolThirdArticle'>

      <div className='pageSchoolThirdArticle_text pageSchoolThirdArticletext' >
        <div className='pageSchoolThirdArticletext_partGuide'>
          <ArticlePageSchoolTitle title="La possibilité de compléter l'application d'un guide pédagogique" />
          <ul className='articleWithList'>
        <li>
          Une présentation de la méthode et de sa mise en œuvre sur l’application.
        </li>

        <li>
          Pour chaque niveau : 36 Fiches de préparation, pour les 36 semaines de l’année scolaire.
        </li>

        <li>
          Les évaluations de fin de périodes, pour chaque niveau
        </li>

        <li>
          Le Toolkit
        </li>
      </ul>
        </div>
      
        <div className='pageSchoolThirdArticletext_partDevices'>
          <ArticlePageSchoolTitle title='Les configurations minimales requises sur tablette :'/>
          <SimpleArticle text='Android : version Android 9 pour les tablettes Android <br/>
            Apple : version IOS 12.4.8 <br/>
            Connexion Internet hebdomadaire requise pour charger les <br/> leçons de la semaine et enregistrer le suivi des élèves dans le tableau de bord de l’enseignant. '/>
        </div>
      </div>

      <img className='pageSchoolThirdArticle_classRoomImage' src={require('../../../assets/images/pageSchool/toolkit.png')} />
    </div>
    
    
  )
}

function FifthArticle () {
  return (
    <div className='pageSchoolFifthArticle'>
      <img className='pageSchoolFifthArticle_classRoomImage' src={require('../../../assets/images/pageSchool/ho-activity-children.png')} />
      <div className='pageSchoolFifthArticle_text'>
        <ArticlePageSchoolTitle title='La collaboration Holy Owly for school X Bordas'/>
        <SimpleArticle text="Le partenariat Holy Owly X Bordas, c'est la rencontre d'une jeune pousse devenue experte du numérique et de l'apprentissage de l'anglais pour les enfants de 3 à 12 ans avec une maison d'édition scolaire historique. Fruit de 3 ans de recherche et de développement, Holy Owly a développé une méthode pédagogique révolutionnaire, qui associée à la connaissance du marché de l'éducation de la maison Bordas, va permettre de mettre à disposition des professeurs du 1er degré un outil clé en main. Holy Owly for school permet à l’enseignant de mettre en œuvre une micro-séance d’anglais en toute sérénité sans prendre de risques en ce qui concerne la prononciation ou la structure des phrases."/>

      </div>
    </div>
  )
}


export { SimpleArticle, FirstArticle, SecondArticle, ThirdArticle, FifthArticle };