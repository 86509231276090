import React from 'react';
import styles from '../../styles/style.scss';
import Button from '../../components/Button';
import { FirstArticle, SecondArticle, ThirdArticle, FifthArticle } from './components/ArticleSections'
import YoutubeEmbed from '../../components/YoutubeEmbed';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

function PageSchool (props) {
  return (
    <div className='pageSchool'>
      <Header />
      <div className='pageSchool_wrapper'>
        <div className='pageSchool_logos schoolLogos'>
          <img className='schoolLogos_holyowly' src={require('../../assets/images/pageSchool/ho-forschool.svg')} />
          <h1 className='schoolLogos_X titlePurple'>X</h1>
          <img className='schoolLogos_bordas' src={require('../../assets/images/pageSchool/bordas.svg')} />
        </div>
        <FirstArticle />
        <SecondArticle />
        <ThirdArticle />
        <FifthArticle />
        <a href='https://www.editions-bordas.fr/recherche?text=holy+owly'>
          <Button className='buttonOrangeMedium pageschoolButton' text='ACHETER DES LICENCES'/>
        </a>
        <YoutubeEmbed embedId="AY4Tgta6_Rk" />
      </div>
      <Footer/>
    </div>
  )
}

export default PageSchool;
