import React from "react";

function HeaderLoading() {
  return (
    <div className="headerOnlyLogo">
      <img
        className="headerOnlyLogo_hoLogo"
        src={require("../../assets/images/homePage/logo-HOforSchool.svg")}
      />
      <h1 className="headerOnlyLogo_title titleWhite">Chargement en cours</h1>
    </div>
  );
}

export default HeaderLoading;
