import React, { useState, useEffect, useContext, useCallback } from "react";
import { Route, useRouteMatch, useHistory } from "react-router-dom";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import StudentInputsRow from "./components/StudentInputsRow";
import generator from "generate-password-browser";
import classRoomExtension from "../../../extension/classRoomExtension";
import AddClassFirstTimeText from "../components/EditClassComplex/AddClassFirstTimeText";
import Loader from "../../../components/Loader";

export default function StudentGenerationPost({ useClassRoom, redirectPath }) {
  const firebaseInstance = getFirebase();
  let match = useRouteMatch();
  const firebase = getFirebase();
  const history = useHistory();
  // context
  const [studentsInputsData, setStudentsInputsData] = useState([
    {
      firstName: "",
      lastName: "",
    },
  ]);

  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;
  const [classRoom, setClassRoom] = useClassRoom;
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const classRooms = user.isAdmin()
    ? user.adminTeacher.classRooms
    : user.classRooms;
  const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
  const teacher = user.isAdmin() ? user.adminTeacher : user;
  const isTeacherLicence = user.isAdmin()
    ? user.isTeacherLicence()
    : user.admin.isTeacherLicence();
  const admin = user.isAdmin() ? user : user.admin;

  let nbRemainedLicencesStudent = !isTeacherLicence
    ? admin.nbRemainedLicences
    : 0;

  console.log("nbRemainedLicencesStudent", nbRemainedLicencesStudent);

  const handleBack = () => {
    setClassRoom(null);
  };

  const handleOnSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoadingRequest(true);
      if (firebaseInstance) {
        console.log(studentsInputsData);
        var updatedClassRoom = classRoom;
        const classRoomId = updatedClassRoom.id;
        for (const studentInputsData of studentsInputsData) {
          console.log(studentInputsData);
          try {
            const firstName = studentInputsData.firstName;
            const lastName = studentInputsData.lastName;

            const db = firebase.firestore();
            const docRef = db.collection("student");
            const docAdminRef = db.collection("admin").doc(admin.uid);

            let newStudentInfo;
            let newStudentDoc;
            let doc;

            do {
              const code = generator.generate({
                length: 6,
                numbers: true,
                lowercase: false,
                uppercase: false,
              });

              newStudentInfo = {
                firstName,
                lastName,
                code: code,
                classRoom: db.collection("classRooms").doc(classRoomId),
              };

              newStudentDoc = await docRef.doc(code);
              doc = await newStudentDoc.get();
            } while (doc.exists);

            await newStudentDoc.set(newStudentInfo);

            const newStudentClassRoomList = [
              ...updatedClassRoom.students,
              { ...newStudentInfo, id: newStudentDoc.id },
            ];
            const classRoomCollection = db.collection("classRooms");
            const currentClassRoomDocRef = classRoomCollection.doc(
              updatedClassRoom.id
            );
            const newStudentClassRoomListRef = newStudentClassRoomList.map(
              (classRoomMapItem) => db.doc(`student/${classRoomMapItem.id}`)
            );
            await currentClassRoomDocRef.set(
              { students: newStudentClassRoomListRef },
              { merge: true }
            );

            const newClassRooms = classRooms.map((classRoomItem) => {
              if (classRoomItem.id != classRoomId) {
                return classRoomItem;
              }
              return classRoomExtension({
                ...classRoomItem,
                students: newStudentClassRoomList,
              });
            });

            updatedClassRoom = newClassRooms.filter(
              (classRoom) => classRoom.id == updatedClassRoom.id
            )[0];

            let nbRemainedLicences = nbRemainedLicencesStudent;
            if (admin.isStudentLicence()) {
              nbRemainedLicences = await user.getNumberOfRemainedLicence();
              await docAdminRef.set({ nbRemainedLicences }, { merge: true });
            }
            nbRemainedLicencesStudent = nbRemainedLicences;

            if (user.isAdmin()) {
              setUser({
                ...user,
                adminTeacher: { ...teacher, classRooms: newClassRooms },
                nbRemainedLicences,
              });
            } else {
              setUser({
                ...user,
                classRooms: newClassRooms,
                admin: { ...user.admin, nbRemainedLicences },
              });
            }

            console.log(`student saved  ${newStudentDoc.id}`);
          } catch (error) {
            console.log(error);
          } finally {
            setIsLoadingRequest(false);
          }
        }
        history.push(redirectPath);
      }
    },
    [setIsLoadingRequest, studentsInputsData]
  );

  const handleFirstNameChanged = (e, index) => {
    const newStudentInput = studentsInputsData.map((studentInputsData, i) => {
      if (index !== i) {
        return studentInputsData;
      }

      const firstName = e.target.value;
      const lastName = studentInputsData.lastName;
      return {
        firstName,
        lastName,
      };
    });
    setStudentsInputsData(newStudentInput);
  };

  const handleLastNameChanged = (e, index) => {
    const newStudentInput = studentsInputsData.map((studentInputsData, i) => {
      if (index !== i) {
        return studentInputsData;
      }

      const firstName = studentInputsData.firstName;
      const lastName = e.target.value;
      return {
        firstName,
        lastName,
      };
    });
    setStudentsInputsData(newStudentInput);
  };

  const handleAddInput = (e) => {
    setStudentsInputsData([
      ...studentsInputsData,
      {
        firstName: "",
        lastName: "",
      },
    ]);
  };

  const handleDelete = (e, index) => {
    e.preventDefault;
    setStudentsInputsData(
      studentsInputsData.filter((studentInputsData, i) => index != i)
    );
  };
  return (
    <div className="studentGenerationPost">
      <AddClassFirstTimeText user={user} />
      <div className="classHeader">
        <img
          className="classHeader_buttonReturn"
          onClick={handleBack}
          src={require("../../../assets/images/arrowBackButton.svg")}
        />
        <h1 className="classHeader_classTitle mediumTitleOrange">
          {classRoom.name}
        </h1>
        <div></div>
      </div>
      <form
        className="studentGenerationPost_formAddChild formAddChild"
        onSubmit={handleOnSubmit}
      >
        {!isLoadingRequest ? (
          <>
            {studentsInputsData.map((studentInputsData, index) => {
              return (
                <StudentInputsRow
                  key={index}
                  index={index}
                  studentInputsData={studentInputsData}
                  handleFirstNameChanged={handleFirstNameChanged}
                  handleLastNameChanged={handleLastNameChanged}
                  handleDelete={handleDelete}
                />
              );
            })}

            {nbRemainedLicencesStudent - studentsInputsData.length > 0 ? (
              <img
                className="formAddChild_buttonAdd"
                src={require("../../../assets/images/pageSchool/icon_addOrange.svg")}
                onClick={handleAddInput}
              />
            ) : undefined}

            <button className="formAddChild_buttonValidation">VALIDER</button>
          </>
        ) : (
          <Loader />
        )}
      </form>
    </div>
  );
}
