import React, { useState, useEffect, useContext, Component } from "react";
import LabelForm from "../../../Home/components/LabelForm";


export default function StudentInputsRow({studentInputsData, handleFirstNameChanged, handleLastNameChanged, index, handleDelete }) {

    return (
        <div>
            <div className='studentInputsRow'>
                <LabelForm 
                    key="studentFirstName"
                    labelFormClassName='formAddChildOnClass formAddChildOnClassFirstName'
                    labelClassName='formAddChildOnClass_label'
                    labelText="Prénom de l'élève"
                    inputClassName='formAddChildOnClass_input'
                    placeholder="Prénom de l'élève"
                    inputName='studentFirstName'
                    inputType="text"
                    required={true}
                    useInput={{ onChange: (e) => handleFirstNameChanged(e, index), value: studentInputsData.firstName}}
                />
                <LabelForm 
                    key="studentLastName"
                    labelFormClassName='formAddChildOnClass'
                    labelClassName='formAddChildOnClass_label'
                    labelText="Initial du nom de famille de l'éleve"
                    inputClassName='formAddChildOnClass_input'
                    placeholder="Initial du nom de famille"
                    inputName='studentLastName'
                    inputType="text"
                    required={true}
                    useInput={{ onChange: (e) => handleLastNameChanged(e, index), value: studentInputsData.lastName}}
                />
                {/* <button onClick={(e) => handleDelete(e, index)} >delete</button> */}
            </div>
            
        </div>
 
    )
}