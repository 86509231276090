import React, { useContext } from "react";
import LabelForm from "../../../Home/components/LabelForm";
import Button from "../../../../components/Button";
import DeleteButton from "../../../../components/DeleteButton";
import 'firebase/firestore'
import { UserContext} from "../../../../app"
import useRadioButtons from "../../../../hook/useRadioButtons"
import useInput from "../../../../hook/useInput"
import classRoomExtension from '../../../../extension/classRoomExtension'
import generator from 'generate-password-browser';
import useGradeSchoolSelect from "../../../../hook/useGradeSchoolSelect"


function Guide({useHasGuide}) {

  const { useUser, isLoading } = useContext(UserContext)
  
  const useGradeSchool = useGradeSchoolSelect("CP")
  const [hasGuide, guideProps] = useHasGuide
  const useName = useInput("")
  const [user, setUser] = useUser

  const handleChangeSelect = (event) => {
    const val = event.target.value
    useGradeSchool.setValue(event.target.value);
  };

  
  return (
    <div className='guide'>
    
      <p className="guide_text">Vous avez acheté un guide pédagogique :</p>

      <div className='guide_choices guideChoices'>
        <div className='guideChoices_choice'>
          <input required type="radio" value="true" {...guideProps} />
            <label>
              Oui
            </label>
          </div>

      <div className='guideChoices_choice'>
        <input required type="radio" value="false" {...guideProps} />
          <label>
            Non
          </label>
        </div>
      </div>
    </div>
  )
}

export default Guide;