import React, { useState, useEffect, useContext, Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { useEmblaCarousel } from "embla-carousel/react";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import ClassGenerationPostPopup from "../classGeneration/ClassGenerationPostPopup";
import ClassGenerationPutDeletePopup from "../classGeneration/ClassGenerationPutDeletePopup.js";
import StudentGenerationPostPopup from "./StudentGenerationPostPopup";
import StudentGenerationPutDeletePopup from "./StudentGenerationPutDeletePopup";
import "./StudentGenerationGetDelete.css";
import ClassInfo from "../components/ClassInfo";
import Title from "../../../components/Text";
import StudentItem from "./components/StudentItem";
import PrintClassPage from "../printClass/printClassPage";
import Popup from "reactjs-popup";

export default function StudentGenerationGetDelete() {
  const match = useRouteMatch();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    draggable: false,
  });
  const firebase = getFirebase();
  const history = useHistory();
  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  const admin = user.isAdmin() ? user : user.admin;
  const hasRemainedLicences = admin.nbRemainedLicences > 0;

  useEffect(() => {
    if (emblaRef != undefined && emblaApi != undefined) {
      emblaApi.reInit();
    }
  }, [user]);

  const deleteClassRoomAction = async (classRoomId) => {
    const db = firebase.firestore();
    const ref = db.collection("classRooms");
    const docAdminRef = db.collection("admin").doc(admin.uid);
    try {
      const classRooms = user.isAdmin()
        ? user.adminTeacher.classRooms
        : user.classRooms;
      const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
      const teacher = user.isAdmin() ? user.adminTeacher : user;
      await ref.doc(classRoomId).delete();
      let deletedClassRoom = classRooms.find(
        (classRoom) => classRoom.id == classRoomId
      );
      let nbStudentInDeletedClassRoom = deletedClassRoom.students.length;
      let newListOfClassRoom = classRooms.filter(
        (classRoom) => classRoom.id != classRoomId
      );

      if (newListOfClassRoom === undefined) {
        newListOfClassRoom = [];
      }

      const db = firebase.firestore();
      const teacherCollection = db.collection("teacher");
      const currentTeacherDocRef = teacherCollection.doc(userId);

      const newTeacherClassRoomListRef = newListOfClassRoom.map((classRoom) =>
        db.doc(`classRooms/${classRoom.id}`)
      );

      const data = {
        classRooms: newTeacherClassRoomListRef,
      };

      await currentTeacherDocRef.set(data, { merge: true });

      let nbRemainedLicences = admin.nbRemainedLicences;
      if (admin.isStudentLicence()) {
        nbRemainedLicences =
          admin.nbRemainedLicences + nbStudentInDeletedClassRoom;
        await docAdminRef.set({ nbRemainedLicences }, { merge: true });
      }

      if (user.isAdmin()) {
        setUser({
          ...user,
          adminTeacher: { ...teacher, classRooms: newListOfClassRoom },
          nbRemainedLicences,
        });
      } else {
        setUser({
          ...user,
          classRooms: newListOfClassRoom,
          nbRemainedLicences,
          admin: {
            ...user.admin,
            nbRemainedLicences,
          },
        });
      }
    } catch (error) {
      console.error("Error removing document: ", error);
    }
  };

  let container;
  if (!isLoading && user.isSignIn()) {
    const classRooms = user.isAdmin()
      ? user.adminTeacher.classRooms
      : user.classRooms;

    const classRoomInfoList = classRooms.map((classRoom) => {
      const studentRows = classRoom.students.map((student) => {
        return (
          <div className="studentAllInfo">
            <StudentItem
              studentId={student.id}
              studentName={`${student.firstName} ${student.lastName}`}
            />
            <div>
              <Link
                to={{ pathname: `${match.path}/studentReport/${student.id}` }}
              >
                <img
                  className="studentAllInfo_btnGetReport"
                  src={require("../../../assets/images/pageSchool/icon-progressStudent.svg")}
                />
              </Link>
              <img
                className="studentAllInfo_btnEdit"
                onClick={() =>
                  history.push(
                    `${match.path}/editStudent/${classRoom.id}/${student.id}`
                  )
                }
                src={require("../../../assets/images/pageSchool/icon-editStudent.svg")}
              />
            </div>
          </div>
        );
      });

      return (
        <div
          className="embla__slide"
          style={{
            backgroundColor: Math.floor(Math.random() * 16777215).toString(16),
          }}
          key={classRoom.id}
        >
          <div className="classes">
            <div className="classOnCaroussel">
              <a
                onClick={() => {
                  emblaApi.scrollPrev();
                }}
              >
                <img
                  className="editClassOnCaroussel"
                  src={require("../../../assets/images/homePage/icon-arrowLeft.svg")}
                />
              </a>
              <ClassInfo
                className={classRoom.name}
                classCode={admin.isStudentLicence() ? classRoom.code : ""}
                user={user}
              />
              <a
                onClick={() => {
                  emblaApi.scrollNext();
                }}
              >
                <img
                  className="editClassOnCaroussel"
                  src={require("../../../assets/images/homePage/icon-arrowRight.svg")}
                />
              </a>
            </div>
            <a
              onClick={() =>
                history.push(`${match.path}/editSchoolPopup/${classRoom.id}`)
              }
            >
              <img
                className="editClassOnCaroussel"
                src={require("../../../assets/images/homePage/icon-edit.svg")}
              />
            </a>

            <Link
              to={{ pathname: `/printClass/${classRoom.id}` }}
              target="_blank"
            >
              <img
                className="editClassOnCaroussel"
                src={require("../../../assets/images/homePage/icon-print.svg")}
              />
            </Link>
          </div>
          <div className="classStudentList">
            {studentRows}
            {hasRemainedLicences && user.isStudentLicence() ? (
              <button
                className="addStudent"
                onClick={() =>
                  history.push(`${match.path}/addStudent/${classRoom.id}`)
                }
              >
                Ajouter un élève
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      );
    });

    let shouldDisplayClassRoomList = classRoomInfoList.length > 0;
    if (!shouldDisplayClassRoomList) {
      container = (
        <div>
          <h2>Vous avez inscrit aucun groupe pour le moment</h2>
          <button onClick={() => history.push(`${match.path}/addSchoolPopup`)}>
            Ajouter un groupe
          </button>
        </div>
      );
    } else {
      container = (
        <div>
          <div className="embla" ref={emblaRef}>
            <div className="embla__container">{classRoomInfoList}</div>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <Route path={`${match.path}/addSchoolPopup`}>
        <ClassGenerationPostPopup redirectPath={match.path} />
      </Route>

      <Route
        path={`${match.path}/editSchoolPopup/:classRoomId`}
        children={<ClassGenerationPutDeletePopup redirectPath={match.path} />}
      />

      <Route
        path={`${match.path}/addStudent/:classRoomId`}
        children={<StudentGenerationPostPopup redirectPath={match.path} />}
      />

      <Route
        path={`${match.path}/editStudent/:classRoomId/:studentId`}
        children={<StudentGenerationPutDeletePopup redirectPath={match.path} />}
      />

      <Route path={match.path}>
        <div className="pageProf_contents pageProfContent ">
          <Title
            className="mediumTitlePurple pageProfContent_title"
            text="Ajouter des élèves"
          />
          <p className="pageProfContent_text">
            Gérez vos élèves par groupe de niveau pour mieux appréhender
            l'application
          </p>
          <hr />
          {!isLoading ? container : ""}
        </div>
      </Route>
    </>
  );
}
