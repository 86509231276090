import React, { useState, useEffect, useContext, Component } from "react";
import {
    BrowserRouter as Router,
    useRouteMatch,
    useHistory
} from "react-router-dom";
import getFirebase from "../../../utils/firebase"
import { UserContext } from "../../../app"
import AddClassFirstTime from "../components/EditClassComplex/AddClassFirstTime";
import StudentGenerationPost from "./StudentGenerationPost"

export default function ClassStudentGeneration () {
    const match = useRouteMatch();
    const firebase = getFirebase();
    const history = useHistory();
    // context
    const { useUser, isLoading } = useContext(UserContext)
    const [user, setUser] = useUser

    const admin = (user.isAdmin()) ? user : user.admin
    const teacher = (user.isAdmin()) ? user.adminTeacher : user
    const useClassRoom = useState(null)
    const [classRoom] = useClassRoom

    const backPath = admin ? "/admin/student" : "/teacher"

    return (
        <>
            {!classRoom ? (<AddClassFirstTime redirectPath={backPath} useClassRoom={useClassRoom} />) : (<StudentGenerationPost useClassRoom={useClassRoom} redirectPath={backPath} />)}
        </>
    )
}