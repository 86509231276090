import React, { useState, useCallback, useContext } from "react";
import {
  BrowserRouter as Router,
  useHistory,
  useParams,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import useGradeSchoolSelect from "../../../hook/useGradeSchoolSelect";
import useRadioButtons from "../../../hook/useRadioButtons";
import useInput from "../../../hook/useInput";
import classRoomExtension from "../../../extension/classRoomExtension";
import EditClass from "../components/EditClass";

export default function ClassGenerationPutDeletePopup({ redirectPath }) {
  const { classRoomId } = useParams();

  const firebaseInstance = getFirebase();
  const history = useHistory();
  // context
  const { useUser, isLoading } = useContext(UserContext);

  // hooks
  const [user, setUser] = useUser;

  const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
  const classRooms = user.isAdmin()
    ? user.adminTeacher.classRooms
    : user.classRooms;
  const teacher = user.isAdmin() ? user.adminTeacher : user;

  const classRoom = classRooms.find((classRoom) => classRoom.id == classRoomId);
  const isFromHO = user.isAdminFromHO();
  const useGradeSchool = useGradeSchoolSelect(classRoom.grade, isFromHO);
  const useHasGuide = useRadioButtons("hasGuide", "true");
  const [hasGuide, guideProps] = useHasGuide;
  const useName = useInput(classRoom.name);

  const admin = user.isAdmin() ? user : user.admin;
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const submitForm = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoadingRequest(true);

      const grade = useGradeSchool.value;
      const name = useName.value;
      const hasGuideVal = hasGuide == "true" ? true : false;

      if ((firebaseInstance, user.isSignIn())) {
        try {
          const db = firebase.firestore();
          const docRef = db.collection("classRooms").doc(classRoom.id);

          const students = classRoom.students;
          const studentsRef = students.map((studentItem) => {
            return db.doc(`student/${studentItem.id}`);
          });

          const editionClassRoomInfo = {
            grade,
            name,
            hasGuide: hasGuideVal,
            uidTeacher: teacher.id,
            students: studentsRef,
          };

          await docRef.set(editionClassRoomInfo, { merge: true });

          const updatedTeacherClassRoomList = classRooms.map(
            (classRoomItem) => {
              if (classRoomItem.id != classRoom.id) {
                return classRoomItem;
              } else {
                return {
                  ...classRoomItem,
                  ...editionClassRoomInfo,
                  id: classRoom.id,
                  students,
                };
              }
            }
          );

          const teacherCollection = db.collection("teacher");
          const currentTeacherDocRef = teacherCollection.doc(userId);
          const newTeacherClassRoomListRef = updatedTeacherClassRoomList.map(
            (classRoom) => db.doc(`classRooms/${classRoom.id}`)
          );
          await currentTeacherDocRef.set(
            { classRooms: newTeacherClassRoomListRef },
            { merge: true }
          );

          if (user.isAdmin()) {
            setUser({
              ...user,
              adminTeacher: {
                ...teacher,
                classRooms: updatedTeacherClassRoomList.map((classRoomInfo) =>
                  classRoomExtension(classRoomInfo)
                ),
              },
            });
          } else {
            setUser({
              ...user,
              classRooms: updatedTeacherClassRoomList.map((classRoomInfo) =>
                classRoomExtension(classRoomInfo)
              ),
            });
          }

          history.push(redirectPath);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    },
    [setIsLoadingRequest, setUser, useGradeSchool.value, useName.value, user]
  );

  const closeAction = () => {
    history.push(redirectPath);
  };

  const handleChangeSelect = (event) => {
    const val = event.target.value;
    useGradeSchool.setValue(event.target.value);
  };

  const deleteClassRoomAction = useCallback(
    async (classRoomId) => {
      setIsLoadingRequest(true);
      const db = firebase.firestore();
      const ref = db.collection("classRooms");
      const docAdminRef = db.collection("admin").doc(admin.uid);
      try {
        const classRooms = user.isAdmin()
          ? user.adminTeacher.classRooms
          : user.classRooms;
        const userId = user.isAdmin() ? user.adminTeacher.id : user.id;
        const teacher = user.isAdmin() ? user.adminTeacher : user;
        await ref.doc(classRoomId).delete();
        let deletedClassRoom = classRooms.find(
          (classRoom) => classRoom.id == classRoomId
        );
        let nbStudentInDeletedClassRoom = deletedClassRoom.students.length;
        let newListOfClassRoom = classRooms.filter(
          (classRoom) => classRoom.id != classRoomId
        );

        if (newListOfClassRoom === undefined) {
          newListOfClassRoom = [];
        }

        const db = firebase.firestore();
        const teacherCollection = db.collection("teacher");
        const currentTeacherDocRef = teacherCollection.doc(userId);

        const newTeacherClassRoomListRef = newListOfClassRoom.map((classRoom) =>
          db.doc(`classRooms/${classRoom.id}`)
        );

        const data = {
          classRooms: newTeacherClassRoomListRef,
        };

        await currentTeacherDocRef.set(data, { merge: true });

        let nbRemainedLicences = admin.nbRemainedLicences;
        if (admin.isStudentLicence()) {
          nbRemainedLicences = await user.getNumberOfRemainedLicence();
          await docAdminRef.set({ nbRemainedLicences }, { merge: true });
        }

        history.push(redirectPath);

        if (user.isAdmin()) {
          setUser({
            ...user,
            adminTeacher: { ...teacher, classRooms: newListOfClassRoom },
            nbRemainedLicences,
          });
        } else {
          setUser({
            ...user,
            classRooms: newListOfClassRoom,
            nbRemainedLicences,
            admin: {
              ...user.admin,
              nbRemainedLicences,
            },
          });
        }
      } catch (error) {
        console.error("Error removing document: ", error);
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [setUser, setIsLoadingRequest, user]
  );

  return (
    <div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          opacity: "0.8",
          width: "100vw",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          top: "0",
          left: "0",
          "z-index": "99999",
        }}
      />
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          "z-index": "99999",
          left: "30%",
          top: "10%",
        }}
      >
        {
          <form onSubmit={submitForm}>
            <EditClass
              classRoom={classRoom}
              handleChangeSelect={handleChangeSelect}
              closeOnClick={closeAction}
              deleteOnClick={(e) => {
                e.preventDefault();
                deleteClassRoomAction(classRoom.id);
              }}
              useName={useName}
              useGradeSchool={useGradeSchool}
              useHasGuide={useHasGuide}
              isLoadingRequest={isLoadingRequest}
            />
          </form>
        }
      </div>
    </div>
  );
}
