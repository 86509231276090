import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
} from "react-router-dom";
import TeacherGenerationPost from "./TeacherGenerationPost";
import TeacherGenerationGetDelete from "./TeacherGenerationGetDelete";
import ManagementProfText from '../components/ManagementProfs/ManagementProfsText';

export default function TeacherGeneration({ adminState }) {

    const match = useRouteMatch();
    const teachersState = useState([]);

    return (
        <div className='managementProf'>
        <ManagementProfText classNameTitle='mediumTitlePurple managementProf_title'
          title='Gestion des professeurs'
          classNameText='textGreyMedium'
          text='En tant qu’administrateur vous pouvez nommer les professeurs qui pourront accéder à la solution Holy Owly avec leur(s) classe(s).' />
        <hr className='underLine'/>
            <TeacherGenerationGetDelete teachersState={teachersState} />
            <TeacherGenerationPost teachersState={teachersState} />
        </div >

    )
}

function Topic() {
    let { topicId } = useParams();
    return <h3>Requested topic ID: {topicId}</h3>;
}