import React from "react";
import SocialMediaList from "./SocialMediaList";

function SocialMedia() {
  return (
    <div className='socialMedia'>
      <h3 className='socialMedia_title textFooterMedium'>
        Contact
      </h3>

      <a class='socialMedia_email' href="mailto: contact@holyowly.fr">Support</a>
      
      <div className='socialMedia_list socialMediaList'>
        <img className='socialMediaList_facebook socialMediaListLogo' src={require('../../../assets/images/pageSchool/facebookLogo.svg')} />
        <img className='socialMediaList_instagram socialMediaListLogo' src={require('../../../assets/images/pageSchool/instagramLogo.svg')} />
        <img className='socialMediaList_twitter socialMediaListLogo' src={require('../../../assets/images/pageSchool/twitterLogo.svg')} />
        <img className='socialMediaList_youtube socialMediaListLogo' src={require('../../../assets/images/pageSchool/youtubeLogo.svg')} />
      </div>

      {/* <ul className='socialMedia_menu socialMediaMenuList'>
        {
          SocialMediaList.map((item, index) => {
            return (
              <li className='socialMedia_list textFooterSmall' key={index}>
                <a className={item.cName} href = {item.url}></a>
                {item.title}
              </li>
            )
          })
        }
        
      </ul> */}
      
    </div>
  )
}

export default SocialMedia;