import React from "react";
import Button from "./Button";

//the style is using the same className like in the component ** adminMenu **

function PopUpSuccesAddClass() {
  return (
    <div className='PopUpSuccesAddClass'>
      <img />
      <h2 className='PopUpSuccesAddClass_title mediumTitleOrange'>Groupe créé avec succès</h2>
      <p className='PopUpSuccesAddClass_text'>Bravo ! Vous venez de créer votre groupe.
        Vous pouvez dès à présent projeter les exercices via l’application Holy Owly for School.
      </p>
      <Button className='PopUpSuccesAddClass_button' text='COMPRIS'/>
    </div>
  )
}

export default PopUpSuccesAddClass;