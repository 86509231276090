import React, { useState } from 'react';


const useGradeSchoolSelect = (initialValue, isFromHO) => {
    let schoolGrade = []
    if (isFromHO) {
        schoolGrade = [
            "PS",
            "MS",
            "GS",
            "CP",
            "CE1",
            "CE2",
            "CM1",
            "CM2",
            "6eme"
        ]
    } else {
        schoolGrade = [
            "CP",
            "CE1",
            "CE2",
            "CM1",
            "CM2",
            "6eme"
        ]
    }

    const [value, setValue] = useState(initialValue);

    const handleChangeSelect = (event) => {
        const val = event.target.value
        setValue(event.target.value);
    };

    return {
        schoolGrade,
        value,
        setValue,
        handleChangeSelect
    };
};

export default useGradeSchoolSelect