import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import getFirebase from "../../../utils/firebase";
import ReportProgressBar from "../components/StudentReport/ReportProgressBar";
import ReportProgram from "../components/StudentReport/ReportProgram";
import ReportStat from "../components/StudentReport/ReportStat";

export default function StudentReport({ redirectPath }) {
  const { studentId } = useParams();
  const history = useHistory();
  const firebase = getFirebase();
  const db = firebase.firestore();
  const [isLoaded, setLoaded] = useState(false);
  const [student, setStudent] = useState(undefined);
  const [stats, setStats] = useState(undefined);

  const closeAction = useCallback(() => {
    history.push(redirectPath);
  }, [history, redirectPath]);

  useEffect(() => {
    (async () => {
      setLoaded(false);
      const TOTAL_POINT_ANSWERS = 168;
      const studentQuery = await db.collection("student").doc(studentId);
      const studentDoc = await studentQuery.get();
      const student = studentDoc.data();
      setStudent(student);

      let stats = [];
      const snapshotStats = await studentQuery.collection("stats").get();

      if (!snapshotStats.empty) {
        for (const docStat of snapshotStats.docs) {
          const stat = docStat.data();
          const progStats = stat.progStats;

          // Update successRate in each progStats
          const progStatsUpdated = progStats.map((progStat) => {
            const dayStats = progStat.dayStats;
            let totalPointDayStat = 0;

            for (let i = 0; i < dayStats.length; i++) {
              let dayStat = dayStats[i];
              const completion = dayStat.completion;

              let sumOfCompletionExercices = 0;

              for (let j = 0; j < completion.length; j++) {
                const completionExercice = completion[j];
                sumOfCompletionExercices =
                  sumOfCompletionExercices + completionExercice;
              }

              totalPointDayStat = totalPointDayStat + sumOfCompletionExercices;
            }

            return {
              ...progStat,
              successRate: Math.round((totalPointDayStat / TOTAL_POINT_ANSWERS) * 100),
            };
          });

          const missionDoc = await stat.missionRef.get();
          const missionData = missionDoc.data();
          let progs = [];

          for (const programmeRef of missionData.programmes) {
            const progDoc = await programmeRef.get();
            const progData = progDoc.data();
            progs.push(progData);
          }

          const mission = {
            ...missionData,
            programmes: progs,
          };
          stats.push({
            ...stat,
            progStats: progStatsUpdated,
            mission,
          });
        }
      }
      setStats(stats);
    })();
  }, []);

  useEffect(() => {
    if (stats !== undefined && student !== undefined) {
      console.log("[StudentReport]: loaded");
      console.log(stats);
      console.log(student);
      setLoaded(true);
    }
  }, [student, stats, setLoaded]);

  const renderStatComponent = useCallback((stats) => {
    return stats.map((stat) => {
      return <ReportStat stat={stat} />;
    });
  }, []);

  return (
    <div>
      <a href="#" onClick={closeAction}>
        <img
          className="editClass_buttonClose"
          src={require("../../../assets/images/homePage/iconClose.svg")}
        />
      </a>
      {isLoaded ? (
        <div className="childReport">{renderStatComponent(stats)}</div>
      ) : (
        <h1>Chargement</h1>
      )}
    </div>
  );
}

// {stats[0].mission.programmes[0].nom}