import firebase from "firebase";

const LICENCE_TYPE = {
  STUDENT: "student",
  TEACHER: "teacher",
};

const hasDefaultPassword = (user) => {
  return () => {
    {
      if (user == null) {
        return false;
      }

      const hasDefaultPassword = user.defaultPassword;

      if (hasDefaultPassword != undefined) {
        return hasDefaultPassword;
      } else {
        return false;
      }
    }
  };
};

const isFirstTimeChoiceAdminType = (user) => {
  return () => {
    {
      if (user == null) {
        return false;
      }

      const firstTimeChoiceAdminType = user.firstTimeChoiceAdminType;

      if (firstTimeChoiceAdminType != undefined) {
        return firstTimeChoiceAdminType;
      } else {
        return true;
      }
    }
  };
};

const isTeacherLicence = (user) => {
  return () => {
    const licenceType = user.licenceType ?? user.admin.licenceType;

    if (user == null || licenceType === undefined) {
      return false;
    }

    return licenceType == LICENCE_TYPE.TEACHER;
  };
};

const isStudentLicence = (user) => {
  return () => {
    const licenceType = user.licenceType ?? user.admin.licenceType;

    if (user == null || licenceType === undefined) {
      return false;
    }

    return licenceType == LICENCE_TYPE.STUDENT;
  };
};

const isSignIn = (user) => {
  return () => {
    {
      return user !== null;
    }
  };
};

const isTeacher = (user) => {
  return () => {
    if (user == null || user.type === undefined) {
      return false;
    }

    let isTeacher = user.type == "teacher";

    if (isTeacher == true) {
      return true;
    } else if (user.type == "admin") {
      return user.adminTeacher != undefined ? true : false;
    } else {
      return false;
    }
  };
};

const userType = (user) => {
  return () => {
    if (user.type == "admin") {
      return "admin";
    } else if (user.type == "teacher") {
      return "teacher";
    } else {
      return null;
    }
  };
};

const isAdmin = (user) => {
  return () => {
    if (user == null || user.type === undefined) {
      return false;
    }

    return user.type == "admin";
  };
};

const getNumberOfRemainedLicence = (user) => {
  return async () => {
    const isAdministrator = isAdmin(user)();
    const admin = isAdministrator ? user : user.admin;
    const isTypeTeacher = isTeacher(user)();
    const isAdminTeacher = isAdministrator && isTypeTeacher;
    const licenceType = admin.licenceType;
    const nbLicences = admin.nbLicences;

    const isTeacherLicence = licenceType == LICENCE_TYPE.TEACHER;
    const isStudentLicence = licenceType == LICENCE_TYPE.STUDENT;

    if (isTeacherLicence) {
      const isTeacherAdmin = admin.adminTeacher != undefined;
      const nbTeachers = admin.teachers.length + (isTeacherAdmin ? 1 : 0);
      console.log(
        `Admin ${admin.uid} has a licence of type ${LICENCE_TYPE.TEACHER} with nbLicence: ${nbLicences}`
      );
      return nbLicences - nbTeachers;
    } else if (isStudentLicence) {
      let nbOfStudent = 0;

      if (isAdminTeacher) {
        nbOfStudent = await getNbOfStudentOfTeacher(user.adminTeacher.id);

        nbOfStudent =
          nbOfStudent +
          user.teachers
            .map((t) => {
              const classroomTeacher = t.classRooms;
              return classroomTeacher
                .map((ct) => ct.students.length)
                .reduce((val, current) => {
                  return val + current;
                }, 0);
            })
            .reduce((val, current) => {
              return val + current;
            }, 0);

        return nbLicences - nbOfStudent;
      } else {
        const teachers = admin.teachers;
        if (admin.adminTeacher) {
          const adminTeacherId = admin.adminTeacher.id;
          nbOfStudent =
            nbOfStudent + (await getNbOfStudentOfTeacher(adminTeacherId));
        }

        for (const teacherRef of teachers) {
          nbOfStudent =
            nbOfStudent + (await getNbOfStudentOfTeacher(teacherRef.id));
        }

        return nbLicences - nbOfStudent;
      }
    } else {
      return null;
    }
  };
};

const getNbOfStudentOfTeacher = async (teacherId) => {
  const db = firebase.firestore();
  const docRef = db.collection("teacher");
  const teacherQuery = await docRef.doc(teacherId).get();
  const teacherData = teacherQuery.data();

  let total = 0;
  for (const classRoom of teacherData.classRooms) {
    let classRoomDoc = await db
      .collection("classRooms")
      .doc(classRoom.id)
      .get();
    if (classRoomDoc.exists) {
      const data = classRoomDoc.data();
      total = total + data.students.length;
    }
  }

  return total;
};

const isAdminFromHO = (user) => {
  return () => {
    const idClient = user.idClient ?? user.admin.idClient;

    if (user == null || idClient === undefined) {
      return false;
    }

    return idClient.includes("SCHOOL_");
  };
};

// const isTeacher = (user) => {
//     return () => {
//         if (user == null || user.type === undefined) {
//             return false
//         }

//         let isTeacher = user.type == "teacher"
//         let admin

//         if (isTeacher == true) {
//             return true
//         } else if (user.type == "admin") {
//             return user.adminTeacher != undefined ? true : false
//         } else {
//             return false
//         }
//     }
//}

export default (user) => {
  return {
    ...user,
    isFirstTimeChoiceAdminType: isFirstTimeChoiceAdminType(user),
    hasDefaultPassword: hasDefaultPassword(user),
    isSignIn: isSignIn(user),
    isAdmin: isAdmin(user),
    isTeacher: isTeacher(user),
    userType: userType(user),
    isTeacherLicence: isTeacherLicence(user),
    isStudentLicence: isStudentLicence(user),
    getNumberOfRemainedLicence: getNumberOfRemainedLicence(user),
    isAdminFromHO: isAdminFromHO(user),
  };
};
