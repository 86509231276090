import React from "react";
import LabelForm from "../../Home/components/LabelForm";
import Button from "../../../components/Button";
import Loader from "../../../components/Loader";

const AddStudentForm = ({
  useFirstName,
  useLastName,
  closeOnClick,
  isLoadingRequest,
}) => {
  return (
    <div style={{ backgroundColor: "white" }} className="addStudentForm">
      {!isLoadingRequest ? (
        <>
          {" "}
          <a href="#" onClick={closeOnClick}>
            <img
              className="addStudentForm_buttonClose"
              src={require("../../../assets/images/homePage/iconClose.svg")}
            />
          </a>
          <h2 className="addStudentForm_title mediumTitleOrange">
            Fiche élève
          </h2>
          <p className="addStudentForm_subTitle">
            Ajouter un nouvel élève à la classe
          </p>
          <LabelForm
            labelFormClassName="formAddstudentClass"
            labelClassName="formAddstudentClass_label"
            labelText="Prénom de l’élève"
            inputClassName="addStudentClassInput"
            inputName="studentFirstName"
            inputPlaceholder="Prénom de l’élève"
            useInput={useFirstName}
            required={true}
          />
          <LabelForm
            labelFormClassName="formAddstudentClass"
            labelClassName="formAddstudentClass_label"
            labelText="Initial du nom de famille de l’élève"
            inputClassName="addStudentClassInput"
            inputName="studentInitialName"
            inputPlaceholder="Initial du nom de famille"
            useInput={useLastName}
            required={true}
          />
          <Button className="buttonPopUpPurple" text="AJOUTER" />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default AddStudentForm;
