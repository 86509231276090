import React from "react";
import LabelForm from "../../Home/components/LabelForm";
import Button from "../../../components/Button";
import DeleteButton from "../../../components/DeleteButton";
import Loader from "../../../components/Loader";

//the style is using the same className like in the component ** adminMenu **

function EditClass({
  classRoom,
  handleChangeSelect,
  closeOnClick,
  deleteOnClick,
  useName,
  useGradeSchool,
  useHasGuide,
  isLoadingRequest,
}) {
  return (
    <div style={{ backgroundColor: "white" }} className="editClass">
      {!isLoadingRequest ? (
        <>
          <a href="#" onClick={closeOnClick}>
            <img
              className="editClass_buttonClose"
              src={require("../../../assets/images/homePage/iconClose.svg")}
            />
          </a>
          <h2 className="editClass_title mediumTitleOrange">Fiche classe</h2>
          <p className="editClass_subTitle">
            Pour plus de détails, rendez-vous sur l’application Holy Owly
            School.
          </p>
          <form className="formAddstudentClass">
            <label className="formAddstudentClass_label">Classe</label>
            <select
              name="class"
              className="addStudentClassInput"
              required
              value={useGradeSchool.value}
              onChange={handleChangeSelect}
            >
              {useGradeSchool.schoolGrade.map((schoolGrade) => {
                return (
                  <option
                    className="selectValue"
                    key={schoolGrade}
                    value={schoolGrade}
                  >
                    {schoolGrade}
                  </option>
                );
              })}
            </select>
          </form>
          <LabelForm
            labelFormClassName="formAddstudentClass"
            labelClassName="formAddstudentClass_label"
            labelText="Nom du groupe"
            inputClassName="addStudentClassInput"
            inputName="className"
            inputPlaceholder="CP 01"
            useInput={useName}
            required={true}
          />
          <div className="editStudent_editButtons">
            <DeleteButton
              onClick={deleteOnClick}
              deleteText="SUPPRIMER LA CLASSE"
            />
            <Button
              type="submit"
              className="buttonPopUpPurple"
              text="ENREGISTRER"
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default EditClass;
