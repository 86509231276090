import React from "react";

function CopyRight() {
  return (
    <div className='copyRight'>
      <h5 className='copyRight_text'>
        COPYRIGHT HOLY OWLY 2021
      </h5>
      <div className='copyRight_details'>
        <a className='textFooterMini'>Conditions de ventes</a>
        <a className='textFooterMini'>Mentions légales</a>
      </div>
      
    </div>
  )
}

export default CopyRight;