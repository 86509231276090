import React from 'react';

function LabelForm({ innerRef, labelFormClassName, labelClassName, labelText, inputClassName, inputName, inputPlaceholder, useInput, required, inputType}) {
  const userInputData = (useInput != undefined) ? useInput : {}
  return (
    <div className={labelFormClassName}>
      <label className={labelClassName}>
        {labelText}
      </label>
      <input type={inputType ?? 'text'} required={required} ref={(innerRef != undefined) ? innerRef : null} className={inputClassName} name={inputName} placehoder={inputPlaceholder} {...userInputData}/>
    </div>
  )
}

export default LabelForm;
