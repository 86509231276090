import React from 'react';

function SubscriptionInfo({title,text}) {
  return (
    <div className='subscriptionInfo'>
      <h5 className='subscriptionInfo_title textGreyMedium'>{title}</h5>
      <p className='subscriptionInfo_text'>{text}</p>
    </div>
  )
}
export default SubscriptionInfo;