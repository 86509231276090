import React from "react";

//the style is using the same className like in the component ** adminMenu **

function ClassInfo({className, classCode, user}) {
  return (
    <div className='classInfo'>
      <h3 className='classInfo_className smalltitleOrange'>{className}</h3>
      {(user.isStudentLicence()) ? (<p className='classInfo_classId'>code classe: <span className='classInfo_classCode'>{classCode}</span></p>) : null }
    </div>
  )
}

export default ClassInfo;