import React from "react";
import generator from 'generate-password-browser';


function AddClassFirstTimeText({nbRemainedLicencesStudent, user}) {

  return (
    <div className='addClassFirstTime_text addClassFirstTimeText'>
      <h2 className='mediumTitlePurple addClassFirstTimeText_title'>Ajouter des groupes</h2>
      {(user.isStudentLicence()) ? (<p className='textGreyMedium addClassFirstTimeText_subTitle'>Avec votre abonnement, vous pouvez ajouter les groupes et jusqu’à {nbRemainedLicencesStudent} élèves au total. Chaque groupe représente un niveau. Cela permettra aux élèves d’avoir des exercices selon leur niveau.</p>) : (<p className='textGreyMedium addClassFirstTimeText_subTitle'>Avec votre abonnement, vous pouvez ajouter les groupes. Chaque groupe représente un niveau. Cela permettra aux élèves d’avoir des exercices selon leur niveau.</p>) }
    </div>
  )
}

export default AddClassFirstTimeText;