import React, { useCallback } from "react";
import ReportProgram from "./ReportProgram";

export default function ReportStat({stat}) {
  const renderProgramComponent = useCallback((progs, progStats) => {
    return progs.map((prog, index) => {
      return (<ReportProgram prog={prog} progStat={progStats[index]} />)
    })
  }, [])
  return (<div className='reportStat'>
    <h2 className='reportStat_missionName mediumTitleOrange'>{stat.mission.name}</h2>
    {renderProgramComponent(stat.mission.programmes, stat.progStats)}
  </div>);
}
