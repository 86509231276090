import React from "react";

function TitleConnection() {
  return (
    <div class='connectionTitle'>
      <img className='connectionTitle_icon' src={require('../../../assets/images/homePage/connection.svg')} />
      <h5 className='connectionTitle_text'>CONTINUER PAR E-MAIL</h5>
    </div>
  )
}

export default TitleConnection;