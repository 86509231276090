import React from 'react';

function AdminInfo({firstName, lastName, telNumber, email, password}) {
  return (
    <div className='adminInfo'>
      <div className='adminInfo_title adminInfoTitle'>
        <h2 className='adminInfoTitle_text'>
          Vos informations
        </h2>
        <img className='adminInfoTitle_iconEdit' src={require('../../../../assets/images/homePage/icon-edit.svg')} />
      </div>
      <div className='adminInfo_allInfos adminAllInfos'>
        <div className='adminAllInfos_infoData adminInfoData'>
          <h5 className='adminInfoData_title'>Nom</h5>
          <p className='adminInfoData_input'>{lastName}</p>
        </div>

        <div className='adminAllInfos_infoData adminInfoData'>
          <h5 className='adminInfoData_title'>Prénom</h5>
          <p className='adminInfoData_input'>{ firstName }</p>
        </div>

        <div className='adminAllInfos_infoData adminInfoData'>
          <h5 className='adminInfoData_title'>Téléphone</h5>
          <p className='adminInfoData_input'>{ telNumber}</p>
        </div>

        <div className='adminAllInfos_infoData adminInfoData'>
          <h5 className='adminInfoData_title'>E-mail</h5>
          <p className='adminInfoData_input'>{ email }</p>
        </div>

        <div className='adminAllInfos_infoData adminInfoData'>
          <h5 className='adminInfoData_title'>Mot de passe</h5>
          <p className='adminInfoData_input'>{ password }</p>
        </div>

      </div>


    </div>
  )
}

export default AdminInfo;