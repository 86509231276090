import React, {useContext} from "react";
import generator from 'generate-password-browser';

import FormAddClassName from "./FormAddClassName";
import firebase from 'firebase/app'
import 'firebase/firestore'
import getFirebase from "../../../../utils/firebase"
import {
  BrowserRouter as Router,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import { UserContext } from "../../../../app"
import useGradeSchoolSelect from "../../../../hook/useGradeSchoolSelect"
import useRadioButtons from "../../../../hook/useRadioButtons"
import useInput from "../../../../hook/useInput"
import classRoomExtension from "../../../../extension/classRoomExtension"
import AddClassFirstTimeText from "./AddClassFirstTimeText";

function AddClassFirstTime({useClassRoom, redirectPath}) {

  const firebaseInstance = getFirebase()
  const history = useHistory();
  // context
  const { useUser, isLoading } = useContext(UserContext)
  

  // hooks
  const useGradeSchool = useGradeSchoolSelect("CP")
  const useHasGuide = useRadioButtons("hasGuide", "true");
  const [hasGuide, guideProps] = useHasGuide
  const [classRoom, setClassRoom] = useClassRoom
  const useName = useInput("")
  const [user, setUser] = useUser
  const admin = (user.isAdmin()) ? user : user.admin
  const isTeacherLicence = (user.isAdmin()) ? user.isTeacherLicence() : user.admin.isTeacherLicence()
  const nbRemainedLicencesStudent = (!isTeacherLicence) ? admin.nbRemainedLicences : 0
  const hasRemainedStudentLicences = nbRemainedLicencesStudent > 0
  const isAllowToAddChildren = hasRemainedStudentLicences

  console.log(`coucou ${isTeacherLicence}`)
  const handleSubmitForm = async (event) => {
      event.preventDefault()

      const grade = useGradeSchool.value
      const name = useName.value
      const hasGuideVal = hasGuide == "true" ? true : false

      if (firebaseInstance, user.isSignIn()) {
          try {
              const db = firebase.firestore();
              const docRef = db.collection("classRooms");
              const userId = (user.isAdmin()) ? user.adminTeacher.id : user.id
              const classRooms = (user.isAdmin()) ? user.adminTeacher.classRooms : user.classRooms
              const teacher = (user.isAdmin()) ? user.adminTeacher : user

              let newClassRoom
              let newClassRoomInfo
              let doc
              do {
                  const code = generator.generate({
                      length: 6,
                      numbers: true,
                      lowercase: false,
                      uppercase: false
                  });

                  newClassRoomInfo = {
                      grade,
                      name,
                      hasGuide: hasGuideVal,
                      code,
                      zone: teacher.zone,
                      uidTeacher: teacher.id,
                      students: []
                  }

                  newClassRoom = await docRef.doc(code)
                  doc = await newClassRoom.get();

              } while(doc.exists)

              await newClassRoom.set(
                  newClassRoomInfo
              );

              const newTeacherClassRoomList = [...classRooms, { ...newClassRoomInfo, id: newClassRoom.id }]
              const teacherCollection = db.collection("teacher")
              const currentTeacherDocRef = teacherCollection.doc(userId);
              const newTeacherClassRoomListRef = newTeacherClassRoomList.map((classRoom) => db.doc(`classRooms/${classRoom.id}`))
              console.log(newTeacherClassRoomListRef)
              await currentTeacherDocRef.set({ classRooms: newTeacherClassRoomListRef }, { merge: true });

              if (user.isAdmin()) {
                  setUser({ ...user, adminTeacher: { ...teacher, classRooms: newTeacherClassRoomList.map((classRoomInfo) => classRoomExtension(classRoomInfo)) } })
              } else {
                  setUser({ ...teacher, classRooms: newTeacherClassRoomList.map((classRoomInfo) => classRoomExtension(classRoomInfo)) })
              }

              if (isAllowToAddChildren) {
                setClassRoom({...newClassRoomInfo, id: newClassRoom.id})
              } else {
                history.push(redirectPath)
              }
          } catch (error) {
              console.log(error)
          }
      }
  }

  const closeAction = () => {
      history.push(redirectPath);
  }


  return (<div className='addClassFirstTime'>
    <AddClassFirstTimeText user={user} nbRemainedLicencesStudent={nbRemainedLicencesStudent} />
    <FormAddClassName isAllowToAddChildren={isAllowToAddChildren} handleSubmitForm={handleSubmitForm} useGradeSchool={useGradeSchool} useName={useName} useHasGuide={useHasGuide}/>
  </div>)
}

export default AddClassFirstTime;