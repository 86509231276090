import React, { useContext } from "react";
import MenuItems from "./components/MenuItems";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
} from "react-router-dom";
import { UserContext } from "../../app";

function Header() {
  const match = useRouteMatch();
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  return (
    <div className="header">
      <Link to="/">
        <img
          className="header_hoLogo"
          src={require("../../assets/images/homePage/logo-HOforSchool.svg")}
        />
      </Link>
      <img
        className="header_burgermenuIcon"
        src={require("../../assets/images/homePage/burgerMenu.svg")}
      />

      <ul className="header_menu menuList">
        <Link className="menuItem menuItemLink" to="/">
          <li className="menuItem menuItemLink" key="-1">
            Accueil
          </li>
        </Link>
        {MenuItems.map((item, index) => {
          console.log(item.isInternal);
          return item.isInternal ? (
            <Link
              className={item.itemClassName}
              to={`${match.path}/${item.subPath}`}
            >
              <li className="menuItem menuItemLink" key={index}>
                {item.title}
              </li>
            </Link>
          ) : (
            <a className={item.itemClassName} href={item.url}>
              <li className="menuItem menuItemLink" key={index}>
                {item.title}
              </li>
            </a>
          );
        })}
        {!user.isSignIn() && !isLoading ? (
          <div className="headerConnectionButtons">
            <Link to={`/signIn`}>
              <button className="headerConnectionButtons_connexion menuItem menuItemLink">
                Connexion
              </button>
            </Link>
            <a
              href="https://www.editions-bordas.fr/recherche?text=holy+owly"
              className="headerConnectionButtons_signUp"
            >
              <button className="headerConnectionButtons_signUp menuItem menuItemLink">
                Inscription
              </button>
            </a>
          </div>
        ) : (
          <Link
            className="menuItem menuItemLink"
            to={user.isAdmin() ? "/admin" : "/teacher"}
          >
            <li className="menuItem menuItemLink">Mon Compte</li>
          </Link>
        )}
      </ul>
    </div>
  );
}

export default Header;
