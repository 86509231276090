const MenuAdminList = [
  {
    title: 'Gestion des professeurs',
    subPath: '',
    itemClassName: 'adminMenuItem'
  },
  {
    title: 'Vos informations',
    subPath: 'info',
    itemClassName: 'adminMenuItem'
  },
  {
    title: 'Abonnements',
    subPath: 'subscription',
    itemClassName: 'adminMenuItem'
  },
]

export default MenuAdminList;



