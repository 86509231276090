import React from "react";
import LabelForm from "../../Home/components/LabelForm";
import Button from "../../../components/Button";
import DeleteButton from "../../../components/DeleteButton";
import Loader from "../../../components/Loader";

//the style is using the same className like in the component ** adminMenu **

function EditStudent({
  code,
  deleteOnClick,
  closeOnClick,
  useFirstNameInput,
  useLastNameInput,
  isLoadingRequest,
}) {
  return (
    <div style={{ backgroundColor: "white" }} className="editStudent">
      {!isLoadingRequest ? (
        <>
          <a href="#" onClick={closeOnClick}>
            <img
              className="editStudent_buttonClose"
              src={require("../../../assets/images/homePage/iconClose.svg")}
            />
          </a>
          <h2 className="editStudent_title mediumTitleOrange">Fiche élève</h2>
          <p className="editStudent_subTitle">
            Pour plus de détails, rendez-vous sur l’application Holy Owly
            School.
          </p>
          <LabelForm
            labelFormClassName="formAddstudentClass"
            labelClassName="formAddstudentClass_label"
            labelText="Prénom de l’élève"
            inputClassName="addStudentClassInput"
            inputName="studentFirstName"
            inputPlaceholder="Prénom de l’élève"
            useInput={useFirstNameInput}
            required={true}
          />
          <LabelForm
            labelFormClassName="formAddstudentClass"
            labelClassName="formAddstudentClass_label"
            labelText="Initial du nom de famille de l’élève"
            inputClassName="addStudentClassInput"
            inputName="studentInitialName"
            inputPlaceholder="Initial du nom de famille"
            useInput={useLastNameInput}
            required={true}
          />

          <h5 className="editStudent_infoStudentId">Identifiant : {code}</h5>

          <div className="editStudent_editButtons">
            <DeleteButton
              onClick={deleteOnClick}
              deleteText="SUPPRIMER LE PROFIL"
            />
            <Button className="buttonPopUpPurple" text="ENREGISTRER" />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default EditStudent;
