import React, { useEffect, useContext, useState } from 'react'
import queryString from 'query-string'
import getFirebase from "../../utils/firebase"
import 'firebase/auth';
import 'firebase/firestore';
import useInput from '../../hook/useInput'
import { UserContext } from "../../app"
import {
    BrowserRouter as Router,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
    Route,
    Link
} from "react-router-dom";

import UserConnexion from './UserConnexion';
import HeaderOnlyLogo from '../../components/Header/HeaderOnlyLogo';
import HeaderConnection from '../../components/Header/HeaderConnection';
import ResetPassword from './ResetPassword';

export default function SignIn() {
    const firebase = getFirebase()
    const match = useRouteMatch()
    const history = useHistory();
    const location = useLocation()
    const db = firebase.firestore();
    const auth = firebase.auth()
    const params = queryString.parse(location.search)
    const token = params.token

    // context
    const { useUser, isLoading } = useContext(UserContext)
    const [user, setUser] = useUser

    // state
    const [hasLoadingSignInOne, setHasLoadingSignInOne] = useState(false)

    // Ref
    const passwordInput = useInput("");


    useEffect(() => {
        if (token !== undefined && !isLoading && !hasLoadingSignInOne && user.isSignIn() == false) {
            setHasLoadingSignInOne(true);
            signInWithCustomToken();
        } else if (user.isSignIn() == true) {
            if (user.isAdmin()) {
                history.push("/admin");
            } else if (user.isTeacher()) {
                history.push("/teacher");
            }
        }
    }, [user]);



    const signInWithCustomToken = async () => {
        try {
            if (!firebase) return;
            await auth.signInWithCustomToken(token)
        } catch (error) {
            if (error.code === "auth/invalid-custom-token") {
                alert("Le token n'est pas valide")
            }
        }
    };

    const handleLogout = async () => {
        if (user.isSignIn()) {
            setUser(null)
            await firebase.auth().signOut()
        } else {
            history.push("/");
        }
    }
    
    return (
      <div >
        <Switch>
            <Route path={`${match.path}/password`} >
                <ResetPassword />
            </Route>
            <Route path={match.path}>
                    {!isLoading && user.isSignIn() ? <HeaderOnlyLogo /> : <HeaderConnection />}
                    <div className='pageSignIn'>
                        <a onClick={handleLogout}>
                                <img className='buttonReturn' src={require('../../assets/images/arrowBackButton.svg')} />
                                </a>
                        <div className='pageSignIn_mainContent mainContent'>
                            <div className='mainContent_downloadApp downloadApp'>
                                <img className='downloadApp_imgAppHO' src={require('../../assets/images/pageSchool/icon-iphone-owly-school.png')} />
                                <h3 className='smalltitlePurple textDownload'>TÉLÉCHARGER L'APPLICATION</h3>
                                <div className='downloadApp_imgStore'>
                                    <img className='imgStore' src={require('../../assets/images/homePage/logoApple.svg')} />
                                    <img className='imgStore' src={require('../../assets/images/homePage/logoAndroid.svg')} />
                                </div>
                            </div>
                            {!isLoading && !user.isSignIn() ? <UserConnexion /> : ""}
                        </div>
                    </div>
            </Route>
        </Switch>
      </div >
    )
}