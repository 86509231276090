const MenuItems = [
  {
    title: 'Enseignants',
    url: null,
    subPath: '',
    itemClassName: 'menuItem menuItemLink',
    isInternal: true
  },
  {
    title: 'Aide',
    url: 'https://support.holyowly.fr/hc/fr/requests/new',
    subPath: null,
    itemClassName: 'menuItem menuItemLink',
    isInternal: false
  }
]

export default MenuItems;