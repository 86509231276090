import React, { useState, useEffect, useContext } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
} from "react-router-dom";
import moment from 'moment';
import { UserContext } from "../../app"
import Title from '../../components/Text';

import SubscriptionInfo from './components/Subscription/SubscriptionInfo';
import PayerInfo from './components/Subscription/PayerInfo';


export default function AdminSubcription() {

    let match = useRouteMatch()
    const { useUser, isLoading } = useContext(UserContext)
    const [user, setUser] = useUser

    user.dateFinAbo
    let cmdDate;
    if (user.cmdDate != undefined) {
        cmdDate = moment.unix(user.cmdDate.seconds);
    }

    let dateFinAbo;
    if (user.dateFinAbo != undefined) {
        dateFinAbo = moment.unix(user.dateFinAbo.seconds);
    }

    const isSubscriptionExpiredSoon = dateFinAbo != undefined ? moment().diff(dateFinAbo, 'months', true) <= 3 : false


    return (
        <div className='subscriptionPageAdmin'>
            <div className='subscriptionPageAdmin_text subscriptionText'>
                <Title className='mediumTitlePurple subscriptionText_title' text='Abonnement'/>
                <p className='subscriptionText_subText'>
                Pour gérer votre abonnement, veuillez contacter le payeur. 
                </p>
                {!isSubscriptionExpiredSoon ? <p>Attention votre abonnement arrive bientôt a terme, veuillez contacter le payeur si vous souhaitez renouveler votre abonnement</p> : ""}
            </div>
            <div className='subscriptionPageAdmin_detailSubscription'>
                <h5 className='SubscriptionInfo_title textGreyMedium'>Nature de l’abonnement</h5>
                <p className='SubscriptionInfo_text'>Abonnement annuel, pour <span className='numberOfStudent'>{user.nbLicences} </span>{ user.isStudentLicence() ? 'élève/s' : 'professeur/s'}</p>
            </div>
            <div className='subscriptionPageAdmin_subscriptionDates'>
                <SubscriptionInfo title='Date de souscription' text={cmdDate !== undefined ? cmdDate.format("D MMMM YYYY") : ""} />
                <SubscriptionInfo title='Date de fin' text={dateFinAbo !== undefined ? dateFinAbo.format("D MMMM YYYY") : ""} />
            </div>
            <PayerInfo payerFirstName={user.chefEtabNom} payerLastName={user.chefEtabPrenom} payerEmail={user.email}/>
      </div>
    )
}
