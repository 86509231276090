import React, { useState, useEffect, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import AdminGenerationPut from "./AdminGenerationPut";
import { UserContext } from "../../../app";

export default function AdminGenerationGet() {
  let match = useRouteMatch();
  let history = useHistory();

  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [user, setUser] = useUser;

  const firstName = user.isAdmin() ? user.firstName : user.intendantNom;
  const lastName = user.isAdmin() ? user.lastName : user.intendantPrenom;

  return (
    <>
      <Switch>
        <Route path={`${match.path}/edit`}>
          <AdminGenerationPut />
        </Route>
        <Route path={match.path}>
          <div className="adminInfo">
            <div className="adminInfo_title adminInfoTitle">
              <h2 className="adminInfoTitle_text mediumTitlePurple">
                Vos informations
              </h2>
              <a
                href="#"
                onClick={async () => history.push(`${match.path}/edit`)}
              >
                <img
                  className="adminInfoTitle_iconEdit"
                  src={require("../../../assets/images/homePage/icon-edit.svg")}
                />
              </a>
            </div>
            <div className="adminInfo_allInfos adminAllInfos">
              <div className="adminAllInfos_infoData adminInfoData">
                <h5 className="adminInfoData_title">Nom</h5>
                <p className="adminInfoData_input">{lastName}</p>
              </div>

              <div className="adminAllInfos_infoData adminInfoData">
                <h5 className="adminInfoData_title">Prénom</h5>
                <p className="adminInfoData_input">{firstName}</p>
              </div>

              <div className="adminAllInfos_infoData adminInfoData">
                <h5 className="adminInfoData_title">Téléphone</h5>
                <p className="adminInfoData_input">{user.phone}</p>
              </div>

              <div className="adminAllInfos_infoData adminInfoData">
                <h5 className="adminInfoData_title">E-mail</h5>
                <p className="adminInfoData_input">{user.email}</p>
              </div>
            </div>
            <div className="adminInfo_warning adminInfoWarning">
              <h3 className="adminInfoWarning_text">
                Attention, votre nom, prénom et adresse e-mail seront transmis
                aux professeurs.
              </h3>
            </div>
          </div>
        </Route>
      </Switch>
    </>
  );
}
