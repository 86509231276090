const getGradeName = (classRoom) => {
    return () => {
        {
            if (classRoom == null) {
                return "";
            }

            switch (classRoom.schoolGrade) {
                case "0":
                    return "CP"
                case "1":
                    return "CE1"
                case "2":
                    return "CE2"
                case "3":
                    return "CM1"
                case "4":
                    return "CM2"
                default:
                    return ""
            }
        }
    }
}

export default (classRoom) => {
    return {
        ...classRoom,
        getGradeName: getGradeName(classRoom),
    }
}