import React, { useState, useCallback, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import getFirebase from "../../../utils/firebase";
import { UserContext } from "../../../app";
import useInput from "../../../hook/useInput";
import generator from "generate-password-browser";
import AddStudentForm from "../components/AddStudentForm";
import classRoomExtension from "../../../extension/classRoomExtension";

export default function StudentGenerationPostPopup({ redirectPath }) {
  const firebaseInstance = getFirebase();
  const history = useHistory();
  const { classRoomId } = useParams();
  // context
  const { useUser, isLoading } = useContext(UserContext);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  // hooks
  const useFirstName = useInput("");
  const useLastName = useInput("");
  const [user, setUser] = useUser;

  const classRooms = user.isAdmin()
    ? user.adminTeacher.classRooms
    : user.classRooms;
  const teacher = user.isAdmin() ? user.adminTeacher : user;
  const admin = user.isAdmin() ? user : user.admin;

  const submitForm = useCallback(
    async (event) => {
      event.preventDefault();

      setIsLoadingRequest(true);

      const firstName = useFirstName.value;
      const lastName = useLastName.value;
      const classRoom = classRooms.find(
        (classRoomItem) => classRoomItem.id == classRoomId
      );

      if ((firebaseInstance, user.isSignIn())) {
        try {
          const db = firebase.firestore();
          const docRef = db.collection("student");
          const docAdminRef = db.collection("admin").doc(admin.uid);

          let newStudentInfo;
          let newStudentDoc;
          let doc;

          do {
            const code = generator.generate({
              length: 6,
              numbers: true,
              lowercase: false,
              uppercase: false,
            });

            newStudentInfo = {
              firstName,
              lastName,
              code: code,
              classRoom: db.collection("classRooms").doc(classRoomId),
            };

            newStudentDoc = await docRef.doc(code);
            doc = await newStudentDoc.get();
          } while (doc.exists);

          await newStudentDoc.set(newStudentInfo);

          const newStudentClassRoomList = [
            ...classRoom.students,
            { ...newStudentInfo, id: newStudentDoc.id },
          ];
          const classRoomCollection = db.collection("classRooms");
          const currentClassRoomDocRef = classRoomCollection.doc(classRoom.id);
          const newStudentClassRoomListRef = newStudentClassRoomList.map(
            (classRoomMapItem) => db.doc(`student/${classRoomMapItem.id}`)
          );
          console.log(newStudentClassRoomListRef);
          await currentClassRoomDocRef.set(
            { students: newStudentClassRoomListRef },
            { merge: true }
          );

          const newClassRooms = classRooms.map((classRoomItem) => {
            if (classRoomItem.id != classRoomId) {
              return classRoomItem;
            }
            return classRoomExtension({
              ...classRoomItem,
              students: newStudentClassRoomList,
            });
          });

          let nbRemainedLicences = admin.nbRemainedLicences;
          if (admin.isStudentLicence()) {
            nbRemainedLicences = await user.getNumberOfRemainedLicence();
            await docAdminRef.set({ nbRemainedLicences }, { merge: true });
          }

          if (user.isAdmin()) {
            setUser({
              ...user,
              adminTeacher: { ...teacher, classRooms: newClassRooms },
              nbRemainedLicences,
            });
          } else {
            setUser({
              ...user,
              classRooms: newClassRooms,
              admin: { ...user.admin, nbRemainedLicences },
            });
          }

          history.push(redirectPath);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    },
    [setIsLoadingRequest, useFirstName.value, useLastName.value, history]
  );

  const handleChangeSelect = (event) => {
    const val = event.target.value;
    useGradeSchool.setValue(event.target.value);
  };

  return (
    <div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "black",
          opacity: "0.8",
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          top: "0",
          left: "0",
          "z-index": "99999",
        }}
      />
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "15%",
          left: "35%",
          display: "flex",
          flexDirection: "column",
          "z-index": "99999",
        }}
      >
        <form onSubmit={submitForm}>
          <AddStudentForm
            onSubmit={submitForm}
            useFirstName={useFirstName}
            useLastName={useLastName}
            isLoadingRequest={isLoadingRequest}
            closeOnClick={() => history.push(redirectPath)}
          />
        </form>
      </div>
    </div>
  );
}
